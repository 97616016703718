import React, { ReactElement } from "react";

import { Step, StepsWrapper, StepTitle } from "./styled";
import { StepParameters, SVGReactComponent } from "../../../types";

interface StepProps {
  StepsButtonsList: Array<StepParameters>;
  onSwitchToStep: (stepName: string) => void;
}
const getIconComponent = (Icon: SVGReactComponent, isCurrent: boolean) => {
  return (
    <Icon
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
      preserveAspectRatio="xMinYMin meet"
      viewBox="0 0 114 34"
      fillOpacity={isCurrent ? "1" : ".5"}
    />
  );
};
const Steps = (props: StepProps): ReactElement => {
  const { StepsButtonsList } = props;
  const StepsList = StepsButtonsList.map((step: StepParameters, index) => {
    return (
      <Step key={index} disabled={step.isDisabled}>
        {step.background && getIconComponent(step.background, step.isCurrent)}
        <StepTitle isDisabled={step.isDisabled}>{step.stepTitle}</StepTitle>
      </Step>
    );
  });
  return <StepsWrapper>{StepsList}</StepsWrapper>;
};

export default Steps;
