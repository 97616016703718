import React from "react";

import { ClearButton } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { AppState, ContractForms } from "../../../types";
import { setFormState, setFormValues } from "../../../store/actions";

export const ClearBillingAddress = () => {
  const dispatch = useDispatch();
  const contract = useSelector((state: AppState) => state.contract);
  const {
    billingAddress: { isValid: isBillingAddressValid },
  } = contract;
  const onBillingAddressClear = () => {
    dispatch(
      setFormValues(ContractForms.billingAddress, {
        billingFirstName: "",
        billingSurname: "",
        billingZip: "",
        billingCity: "",
        billingCityName: "",
        billingStreet: "",
        billingHouseNumber: "",
        billingEmail: "",
        billingPhoneAreaCode: "",
        billingPhone: "",
      })
    );
    dispatch(
      setFormState(ContractForms.billingAddress, {
        isSubmitting: true,
        isValid: false,
      })
    );
  };
  return (
    <React.Fragment>
      {isBillingAddressValid ? (
        <ClearButton onClick={onBillingAddressClear}>Zurücksetzen</ClearButton>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};
