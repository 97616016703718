import React, { ReactElement } from "react";

import { ButtonWrapper, Image, Title, Description } from "./styled";

interface ButtonWithTextProps {
  image: string;
  imageAlt: string;
  title: string;
  description: string;
  onClick: () => void;
}

const ButtonWithText = ({
  image,
  imageAlt,
  title,
  description,
  onClick,
}: ButtonWithTextProps): ReactElement => {
  return (
    <ButtonWrapper onClick={onClick}>
      <Image src={image} alt={imageAlt} />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </ButtonWrapper>
  );
};

export default ButtonWithText;
