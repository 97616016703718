import React, {
  ReactElement,
  SyntheticEvent,
  useState,
  useEffect,
} from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";

import { Paper } from "../../Paper";
import { TextField } from "../Form";
import { FormRow } from "../CalculatorForm/styled";
import { BillingAddressBlock } from "./BillingAddressBlock";
import {
  ContractForms,
  ContractSteps,
  PersonalDataFormValues,
  BillingAddressFormValues,
} from "../../../types";
import { useFormState } from "../hooks/useFormState";
import { BillingAddressModal } from "./BillingAddressModal";
import {
  DatePicker,
  FormTitleContainer,
  FormTitle,
  BillingAddressContainer,
  BillingInformation,
  BillingInformationContainer,
  IbanWrapper,
  BankName,
  BillingAddressButton,
  FormTitleIban,
} from "./styled";
import { materialUIcomponentsStyles } from "../styled";
import {
  validateFirstName,
  validateLastName,
  validateAreaCode,
  validatePhoneNumber,
  validateEmail,
  validatePassword,
  validateConfirmPassword,
  validateIBAN,
  validateAccountHolderFN,
  validateAccountHolderLN,
} from "../validation";
import { PaymentDetails } from "../Form/PaymentDetails";
import { PersonSVG } from "../../SVG";
import { ReactComponent as Home } from "../../../assets/home.svg";
import { ReactComponent as Account } from "../../../assets/account.svg";
import { ReactComponent as DescriptionIcon } from "../../../assets/description.svg";
import { getDate } from "../ReasonForContractForm/formattedDates";
import { useIban } from "./hooks/useIban";
import { useEmail } from "./hooks/useEmail";
import { ClearBillingAddress } from "./CleareBillingAddress";
import DateFnsAdapter from "@date-io/date-fns";
import { useTrackStepEventByName } from "../hooks/useTrackStepEventByName";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

interface PersonalInformationFormProps {
  isFormSubmitted: boolean;
  isFormEdited: boolean;
  initValues: PersonalDataFormValues;
  billingAddressInitValues: BillingAddressFormValues;
}

const getInitialDate = (date: string | null): Date => {
  const dateFns = new DateFnsAdapter();
  return dateFns.date(date);
};

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

export const PersonalInformationForm = ({
  initValues,
  isFormSubmitted,
  isFormEdited,
  billingAddressInitValues,
}: PersonalInformationFormProps): ReactElement => {
  const сheckboxStyles = useStyles();
  const {
    register,
    unregister,
    errors,
    getValues,
    formState,
    setValue,
    setError,
    clearError,
    watch,
    triggerValidation,
  } = useForm<PersonalDataFormValues>({
    mode: "onBlur",
    defaultValues: initValues,
  });

  useTrackStepEventByName(ContractSteps.personalInformation);

  const [selectedDate, handleDateChange] = useState(
    getInitialDate(initValues.dateOfBirth)
  );
  const [checked, setChecked] = useState(initValues.directDebit);
  const { isValid } = formState;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentDetails, setIsPaymentDetails] = useState(
    initValues.paymentType === "SEPA-Lastschrift"
  );
  const [paymentDetails, setPaymentDetails] = useState(initValues.paymentType);
  const watchPassword = watch("password");
  const handleClose = () => {
    setIsModalOpen(false);
  };
  useFormState(
    isValid,
    isSubmitted,
    isFormEdited,
    getValues,
    ContractForms.personalInformation,
    "reasonForContract"
  );
  useEffect(() => {
    register({ name: "dateOfBirth", type: "custom" }, { required: true });
    register({ name: "directDebit", type: "custom" }, { required: true });
    register({ name: "paymentType", type: "custom" });
    register({ name: "bankName", type: "custom" });
    document.addEventListener("keydown", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
  }, []);
  useEffect(() => {
    setIsSubmitted(isFormSubmitted);
  }, [isFormSubmitted]);
  useEffect(() => {
    if (paymentDetails === "SEPA-Lastschrift") {
      register({ name: "iban", type: "custom" });
      register(
        { name: "firstNameAccountHolder", type: "custom" },
        { validate: validateAccountHolderFN }
      );
      register(
        { name: "lastNameAccountHolder", type: "custom" },
        { validate: validateAccountHolderLN }
      );
      register({ name: "directDebit" }, { required: true });
      setValue("iban", initValues.iban);
      setValue("firstNameAccountHolder", initValues.firstNameAccountHolder);
      setValue("lastNameAccountHolder", initValues.lastNameAccountHolder);
      setValue("directDebit", initValues.directDebit);
    } else {
      unregister("iban");
      unregister("firstNameAccountHolder");
      unregister("lastNameAccountHolder");
      unregister("directDebit");
      setValue("iban", "");
      setValue("firstNameAccountHolder", "");
      setValue("lastNameAccountHolder", "");
      setValue("directDebit", false);
      setIbanNumber("");
      setChecked(false);
    }
  }, [paymentDetails]);
  const [ibanNumber, setIbanNumber] = useState(initValues.iban);
  const ibanData = useIban(ibanNumber, triggerValidation);

  const [email, setEmail] = useState(initValues.emailPrivate);
  const checkEmail = useEmail(email);
  useEffect(() => {
    setValue("bankName", ibanData.data?.bankName || "");
  }, [ibanData]);
  useEffect(() => {
    if (email.length) {
      triggerValidation("emailPrivate");
    }
  }, [checkEmail]);
  const handleChangeCheckbox = (event: SyntheticEvent<EventTarget>) => {
    const { checked } = event.target as HTMLInputElement;
    setChecked(checked);
    setValue("directDebit", checked);
    triggerValidation("directDebit");
  };
  const classes = materialUIcomponentsStyles();

  const formatIbanValue = (value: string) => {
    const iban = value.replace(/\s/g, "");
    const formatIban = iban?.match(/.{1,4}/g)?.join(" ") || "";
    setIbanNumber(formatIban);
  };

  const changePaymentType = (event: SyntheticEvent<EventTarget>) => {
    const { value } = event.target as HTMLInputElement;
    setValue("paymentType", value);
    setIsPaymentDetails(value === "SEPA-Lastschrift");
    setPaymentDetails(value);
  };

  const getCheckedRadioStyles = (isChecked: boolean) => {
    if (!isChecked) {
      return classes.radioButtonContainerStyle;
    }
    return classes.checkedRadio;
  };
  return (
    <Paper>
      <form>
        <FormTitleContainer>
          <PersonSVG width="32px" height="32px" fill="#526DF2" />
          <FormTitle>Persönliche Daten</FormTitle>
        </FormTitleContainer>
        <FormRow>
          <TextField
            id="firstName"
            name="firstName"
            type="text"
            label="Vorname"
            error={Boolean(errors.firstName?.message)}
            helperText={errors.firstName?.message}
            placeholder="Pflichtfeld"
            inputRef={register({ validate: validateFirstName })}
            inputProps={{ maxLength: 30 }}
          />
        </FormRow>
        <FormRow>
          <TextField
            id="surname"
            name="surname"
            type="text"
            label="Nachname"
            error={Boolean(errors.surname?.message)}
            helperText={errors.surname?.message}
            placeholder="Pflichtfeld"
            inputRef={register({ validate: validateLastName })}
            inputProps={{ maxLength: 30 }}
          />
        </FormRow>
        <FormRow>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
            <DatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="Geburtsdatum"
              format="dd.MM.yyyy"
              placeholder="Pflichtfeld"
              value={selectedDate}
              InputAdornmentProps={{
                position: "end",
              }}
              PopoverProps={{ className: `${classes.popoverPropsStyle}` }}
              onError={(error) => {
                error && setError("dateOfBirth", error.toString());
              }}
              onChange={(val) => {
                let date = null;
                try {
                  date = val ? getDate(new Date(val)) : null;
                } catch (err) {
                  date = null;
                }
                clearError("dateOfBirth");
                val && setValue("dateOfBirth", date);
                val && handleDateChange(val);
                triggerValidation("dateOfBirth");
              }}
              invalidDateMessage="Das Mindestalter ist 18 Jahre"
              maxDate={
                new Date(new Date().setFullYear(new Date().getFullYear() - 18))
              }
              maxDateMessage="Das Mindestalter ist 18 Jahre"
            />
          </MuiPickersUtilsProvider>
        </FormRow>
        <FormRow>
          <TextField
            id="phoneMobileAreaCode"
            name="phoneMobileAreaCode"
            type="text"
            label="Vorwahl"
            style={{ width: "159px", marginRight: 0 }}
            error={Boolean(errors.phoneMobileAreaCode?.message)}
            helperText={errors.phoneMobileAreaCode?.message}
            placeholder="Pflichtfeld"
            inputRef={register({ validate: validateAreaCode })}
            inputProps={{ maxLength: 6 }}
          />
          <TextField
            id="phoneMobile"
            name="phoneMobile"
            type="text"
            label="Telefonnummer"
            error={Boolean(errors.phoneMobile?.message)}
            helperText={errors.phoneMobile?.message}
            placeholder="Pflichtfeld"
            inputRef={register({ validate: validatePhoneNumber })}
            inputProps={{ maxLength: 15 }}
          />
        </FormRow>
        <FormTitleContainer>
          <DescriptionIcon />
          <FormTitle>Profilinformation</FormTitle>
        </FormTitleContainer>
        <FormRow>
          <TextField
            id="emailPrivate"
            name="emailPrivate"
            type="text"
            label="E-Mail"
            error={Boolean(errors.emailPrivate?.message)}
            helperText={errors.emailPrivate?.message}
            placeholder="Pflichtfeld"
            inputRef={register({
              validate: validateEmail(checkEmail.data || false),
            })}
            onBlur={(event) => {
              setEmail(event.target.value);
            }}
          />
        </FormRow>
        <FormRow>
          <TextField
            id="password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            name="password"
            label="Passwort"
            error={Boolean(errors.password?.message)}
            helperText={errors.password?.message}
            placeholder="Pflichtfeld"
            inputRef={register({ validate: validatePassword })}
            onBlur={(event) => {
              setValue("password", event.target.value);
              triggerValidation("confirmPassword");
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormRow>
        <FormRow>
          <TextField
            id="confirmPassword"
            name="confirmPassword"
            variant="outlined"
            type={showConfirmPassword ? "text" : "password"}
            label="Passwort wiederholen"
            placeholder="Pflichtfeld"
            helperText={errors.confirmPassword?.message}
            error={Boolean(errors.confirmPassword?.message)}
            inputRef={register({
              validate: validateConfirmPassword(watchPassword),
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => setConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormRow>
        <FormTitleContainer>
          <Home />
          <FormTitle>Rechnungsadresse</FormTitle>
        </FormTitleContainer>
        <BillingAddressContainer>
          <BillingAddressBlock />
          <BillingAddressButton
            onClick={(event: SyntheticEvent) => {
              event.preventDefault();
              setIsModalOpen(true);
            }}
          >
            Rechnungsadresse ändern
          </BillingAddressButton>
          <ClearBillingAddress />
        </BillingAddressContainer>
        <FormTitleIban>
          <Account />
          <FormTitle>Zahlungsinformation</FormTitle>
        </FormTitleIban>
        <FormRow>
          <FormControl
            classes={{
              root: `${classes.formControlStyles}`,
            }}
            component="fieldset"
          >
            <RadioGroup
              row
              aria-label="position"
              name="paymentType"
              onChange={changePaymentType}
              defaultValue={initValues.paymentType}
              classes={{
                root: `${classes.radioButtonsContainerStyle}`,
              }}
            >
              <FormControlLabel
                value="SEPA-Lastschrift"
                classes={{
                  root: `${getCheckedRadioStyles(isPaymentDetails)}`,
                }}
                control={
                  <Radio
                    classes={{ colorPrimary: `${classes.radioButtonStyles}` }}
                    color="primary"
                    name="paymentType"
                  />
                }
                label="SEPA-Lastschrift"
              />
              <FormControlLabel
                value="Überweisung"
                classes={{
                  root: `${getCheckedRadioStyles(!isPaymentDetails)}`,
                }}
                control={
                  <Radio
                    classes={{ colorPrimary: `${classes.radioButtonStyles}` }}
                    color="primary"
                    name="paymentType"
                  />
                }
                label="Überweisung"
              />
            </RadioGroup>
          </FormControl>
        </FormRow>
        {isPaymentDetails && (
          <div>
            <FormRow>
              <TextField
                id="firstNameAccountHolder"
                name="firstNameAccountHolder"
                type="text"
                label="Vorname (Kontoinhaber)"
                error={Boolean(errors.firstNameAccountHolder?.message)}
                helperText={errors.firstNameAccountHolder?.message}
                placeholder="Optional"
                inputRef={register({ validate: validateAccountHolderFN })}
                inputProps={{ maxLength: 50 }}
              />
            </FormRow>
            <FormRow>
              <TextField
                id="lastNameAccountHolder"
                name="lastNameAccountHolder"
                type="text"
                label="Nachname (Kontoinhaber)"
                error={Boolean(errors.lastNameAccountHolder?.message)}
                helperText={errors.lastNameAccountHolder?.message}
                placeholder="Optional"
                inputRef={register({ validate: validateAccountHolderLN })}
                inputProps={{ maxLength: 50 }}
              />
            </FormRow>
            <FormRow>
              <IbanWrapper>
                <TextField
                  id="iban"
                  name="iban"
                  type="text"
                  label="IBAN"
                  error={Boolean(errors.iban?.message)}
                  helperText={errors.iban?.message}
                  placeholder="Pflichtfeld"
                  inputProps={{ maxLength: 30 }}
                  inputRef={register({
                    validate: validateIBAN(Boolean(ibanData?.data?.bankName)),
                  })}
                  value={ibanNumber}
                  onChange={(event) => {
                    formatIbanValue(event.target.value);
                  }}
                />
                <BankName>
                  {ibanData.data ? ibanData.data.bankName : ""}
                </BankName>
              </IbanWrapper>
            </FormRow>
            <FormRow>
              <BillingInformationContainer>
                <Checkbox
                  id="directDebit "
                  name="directDebit"
                  checked={checked}
                  className={сheckboxStyles.root}
                  onChange={handleChangeCheckbox}
                  inputProps={{ "aria-label": "primary checkbox" }}
                  required={true}
                  style={{
                    color: "#4646eb",
                    padding: 0,
                  }}
                />
                <BillingInformation>
                  Hiermit ermächtige ich die homee GmbH, Zahlungen von meinem
                  Konto per Lastschrift einzuziehen. Zugleich beauftrage ich
                  mein Kreditinstitut, die von der homee GmbH auf mein Konto
                  gezogenen Lastschriften einzulösen.
                </BillingInformation>
              </BillingInformationContainer>
            </FormRow>
          </div>
        )}
        {!isPaymentDetails && <PaymentDetails />}
        <BillingAddressModal
          isOpen={isModalOpen}
          handleClose={handleClose}
          billingAddressInitValues={billingAddressInitValues}
        />
      </form>
    </Paper>
  );
};
