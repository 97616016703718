import styled from "styled-components";

export type isDisabled = { isDisabled: boolean };

export const Step = styled.button`
  position: relative;
  width: 114px;
  height: 34px;
  background: white;
  color: black;
  border: none;
  background-color: #f6f9fa;
  &:active {
    border: none;
    outline: 0;
  }
  &:focus {
    border: none;
    outline: 0;
  }
  @media (max-width: 1240px) and (min-width: 992px) {
    width: 108.3px;
    height: 32.3px;
  }
  @media (max-width: 767px) {
    width: 85px;
    height: 24px;
  }
`;
export const StepTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #d5dbdd;
  position: absolute;
  width: 114px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  @media (max-width: 1240px) and (min-width: 992px) {
    width: 90px;
    height: 26.8px;
  }
  @media (max-width: 767px) {
    width: 85px;
    height: 24px;
    font-size: 12px;
  }
  ${(props: isDisabled) =>
    !props.isDisabled &&
    `
      color: white;
    `}
`;
export const StepImgWrapper = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`;
export const StepsWrapper = styled.div`
  display: flex;
  width: 462px;
  justify-content: space-between;
  @media (max-width: 1240px) and (min-width: 992px) {
    width: 360px;
  }
  @media (max-width: 767px) {
    width: 343px;
  }
`;
