import React, { ReactElement } from "react";

import { ButtonsContainer, ButtonBack, ButtonNext } from "./styled";

interface StepProps {
  isDisabled: boolean;
  isBackVisible: boolean;
  onClickNext: () => void;
  onClickBack: () => void;
  buttonName: string;
}

const ButtonsNextBack = ({
  isDisabled,
  isBackVisible,
  onClickNext,
  onClickBack,
  buttonName,
}: StepProps): ReactElement => {
  return (
    <ButtonsContainer>
      {isBackVisible && (
        <ButtonBack variant="grayBorder" onClick={onClickBack} id="zurück">
          Zurück
        </ButtonBack>
      )}
      <ButtonNext
        disabled={isDisabled}
        onClick={onClickNext}
        id={buttonName.toLowerCase()}
      >
        {buttonName}
      </ButtonNext>
    </ButtonsContainer>
  );
};

export default ButtonsNextBack;
