import React, { ReactElement, RefObject } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";

import { Paper } from "../../../Paper";
import { TextField } from "../../Form";
import { FormRow } from "../../CalculatorForm/styled";
import {
  SwitchOfSupplierFormValues,
  Provider,
  ContractSteps,
} from "../../../../types";
import {
  AgreementInformation,
  AgreementInformationBlock,
  BlockBorder,
  BorderBackground,
} from "../styled";
import {
  validateMeterNumber,
  validatePreviousSupplier,
} from "../../validation";
import {
  AutocompleteList,
  AutocompleteOptions,
  AutocompleteWrapper,
} from "../../Form/TextFieldCity/styled";
import { AutocompleteListItem } from "../../Form/TextFieldStreet/styled";
import { datePlusYear, datePlusBusinessDays, getDate } from "../formattedDates";
import { DatePicker } from "../../PersonalInformationForm/styled";

import { useTrackStepEventByName } from "../../hooks/useTrackStepEventByName";
import { useSwitchOfSupplierForm } from "./hooks";
import { useAutocomplete } from "../../../../hooks/useAutocomplete";

export interface SwitchOfSupplierFormProps {
  isFormSubmitted: boolean;
  isFormEdited: boolean;
  initValues: SwitchOfSupplierFormValues;
}

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

export const SwitchOfSupplierForm = ({
  isFormSubmitted,
  isFormEdited,
  initValues,
}: SwitchOfSupplierFormProps): ReactElement => {
  const сheckboxStyles = useStyles();
  const {
    register,
    errors,
    setValue,
    watch,
    triggerValidation,
    setError,
    clearError,
    providers,
    changeContractCancel,
    getCheckedRadioStyles,
    classes,
    isCancellationDate,
    selectedTerminatedDate,
    handleTerminatedDate,
    changeCustomerSpecification,
    customerSpecification,
    selectedDesiredDate,
    handleDesiredChange,
    performRegistration,
    changePerformRegistration,
  } = useSwitchOfSupplierForm({
    isFormSubmitted,
    isFormEdited,
    initValues,
  });

  useTrackStepEventByName(ContractSteps.switchOfSupplier);

  const provider = watch("previousSupplier");

  const handleAutocompleteItemSelection = () => {
    if (!autocomplete?.length) {
      return;
    }

    setValue("previousSupplier", autocomplete[cursor]?.name);
    setValue("previousProviderCodeNumber", autocomplete[cursor]?.codeNumber);
    setIsOpen(false);
    triggerValidation("previousSupplier");
  };

  const {
    setIsOpen,
    wrapperRef,
    isOpen,
    cursor,
    setCursor,
  } = useAutocomplete();

  const filterProviders = (
    providers: Provider[] | null,
    term: string | undefined
  ) => {
    const providerUserInput = term ? term : "";
    return providers?.filter((provider) =>
      provider.name
        .toLocaleLowerCase()
        .replace(/\s/g, "")
        .includes(providerUserInput.toLocaleLowerCase().replace(/\s/g, ""))
    );
  };

  const autocomplete = filterProviders(providers, provider);

  return (
    <Paper>
      <form>
        <FormRow>
          <AutocompleteWrapper ref={wrapperRef as RefObject<HTMLDivElement>}>
            <TextField
              id="previousSupplier"
              name="previousSupplier"
              label="Bisheriger Lieferant"
              value={provider}
              type="text"
              autoComplete="off"
              placeholder="Pflichtfeld"
              onFocus={() => setIsOpen(true)}
              error={Boolean(errors.previousSupplier?.message)}
              helperText={errors.previousSupplier?.message}
              inputProps={{ maxLength: 50 }}
              inputRef={register({
                validate: validatePreviousSupplier(providers || []),
              })}
            />
            {autocomplete &&
              Boolean(autocomplete?.length) &&
              isOpen &&
              autocomplete.length >= 1 && (
                <AutocompleteOptions>
                  <AutocompleteList className="scrollbar">
                    {autocomplete.map((provider, i) => (
                      <AutocompleteListItem
                        key={provider.name}
                        onClick={handleAutocompleteItemSelection}
                        onMouseEnter={() => setCursor(i)}
                        isHovered={cursor === i}
                        tabIndex={0}
                      >
                        {provider.name}
                      </AutocompleteListItem>
                    ))}
                  </AutocompleteList>
                </AutocompleteOptions>
              )}
          </AutocompleteWrapper>
          <input
            className="d-none"
            name="previousProviderCodeNumber"
            type="text"
            ref={register}
          />
        </FormRow>
        <FormRow>
          <TextField
            id="meteringNumber"
            name="meteringNumber"
            type="text"
            label="Zählernummer"
            error={Boolean(errors.meteringNumber?.message)}
            helperText={errors.meteringNumber?.message}
            placeholder="Pflichtfeld"
            inputProps={{ maxLength: 18 }}
            inputRef={register({ validate: validateMeterNumber })}
          />
        </FormRow>
        <BlockBorder className="container">
          <BorderBackground />
        </BlockBorder>
        <FormRow>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="position"
              name="contractCancel"
              onChange={changeContractCancel}
              defaultValue={initValues.contractCancel}
              classes={{
                root: `${classes.radioButtonsContainerStyle}`,
              }}
            >
              <FormControlLabel
                value="stromeeCancel"
                classes={{
                  root: `${getCheckedRadioStyles(!isCancellationDate)}`,
                }}
                control={
                  <Radio
                    classes={{ colorPrimary: `${classes.radioButtonStyles}` }}
                    color="primary"
                    name="contractCancel"
                  />
                }
                label="stromee wird meinen derzeitigen Stromliefervertrag kündigen und alle Formalitäten für mich erledigen"
              />
              <FormControlLabel
                value="alreadyCanceled"
                classes={{
                  root: `${getCheckedRadioStyles(isCancellationDate)}`,
                }}
                control={
                  <Radio
                    classes={{ colorPrimary: `${classes.radioButtonStyles}` }}
                    color="primary"
                    name="contractCancel"
                  />
                }
                label="Ich habe bereits gekündigt"
              />
            </RadioGroup>
          </FormControl>
        </FormRow>
        {isCancellationDate && (
          <FormRow>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
              <DatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Mein Vertrag endet am"
                format="dd.MM.yyyy"
                placeholder="Pflichtfeld"
                value={selectedTerminatedDate}
                InputAdornmentProps={{
                  position: "end",
                }}
                PopoverProps={{ className: `${classes.popoverPropsStyle}` }}
                onError={(error) => {
                  error && setError("terminationDate", error.toString());
                }}
                onChange={(val) => {
                  let date = null;
                  try {
                    date = val ? getDate(new Date(val)) : null;
                  } catch (err) {
                    date = null;
                  }
                  clearError("terminationDate");
                  val && setValue("terminationDate", date);
                  val && handleTerminatedDate(val);
                  triggerValidation(["terminationDate"]);
                }}
                invalidDateMessage="Bitte gib ein gültiges Datum ein"
                minDate={datePlusBusinessDays}
                maxDate={datePlusYear}
                minDateMessage="Bitte gib ein gültiges Datum ein"
              />
            </MuiPickersUtilsProvider>
          </FormRow>
        )}
        <BlockBorder className="container">
          <BorderBackground />
        </BlockBorder>
        {!isCancellationDate ? (
          <React.Fragment>
            <FormRow>
              <FormControl
                component="fieldset"
                classes={{ root: `${classes.formControlStyles}` }}
              >
                <RadioGroup
                  row
                  aria-label="position"
                  name="customerSpecification"
                  onChange={changeCustomerSpecification}
                  value={customerSpecification}
                  classes={{
                    root: `${classes.radioButtonsContainerStyle}`,
                  }}
                >
                  <FormControlLabel
                    value="earliest_possible_date"
                    classes={{
                      root: `${getCheckedRadioStyles(
                        customerSpecification === "earliest_possible_date"
                      )}`,
                    }}
                    control={
                      <Radio
                        classes={{
                          colorPrimary: `${classes.radioButtonStyles}`,
                        }}
                        color="primary"
                        name="customerSpecification"
                      />
                    }
                    label="Nächstmöglicher Termin"
                  />
                  <FormControlLabel
                    value="desired_date"
                    classes={{
                      root: `${getCheckedRadioStyles(
                        customerSpecification === "desired_date"
                      )}`,
                    }}
                    control={
                      <Radio
                        classes={{
                          colorPrimary: `${classes.radioButtonStyles}`,
                        }}
                        color="primary"
                        name="customerSpecification"
                      />
                    }
                    label="Wunschdatum"
                  />
                </RadioGroup>
              </FormControl>
            </FormRow>
            {customerSpecification === "desired_date" ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <DatePicker
                  style={{ marginBottom: "32px" }}
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Wähle dein Wunschdatum aus"
                  format="dd.MM.yyyy"
                  placeholder="Pflichtfeld"
                  value={selectedDesiredDate}
                  InputAdornmentProps={{
                    position: "end",
                  }}
                  PopoverProps={{ className: `${classes.popoverPropsStyle}` }}
                  onError={(error) => {
                    error && setError("desiredDate", error.toString());
                  }}
                  onChange={(val) => {
                    let date = null;
                    try {
                      date = val ? getDate(new Date(val)) : null;
                    } catch (err) {
                      date = null;
                    }
                    clearError("desiredDate");
                    val && setValue("desiredDate", date);
                    val && handleDesiredChange(val);
                    triggerValidation(["desiredDate"]);
                  }}
                  invalidDateMessage="Bitte gib ein gültiges Datum ein"
                  minDate={datePlusBusinessDays}
                  maxDate={datePlusYear}
                  maxDateMessage="Bitte gib ein gültiges Datum ein"
                  minDateMessage="Bitte gib ein gültiges Datum ein"
                />
              </MuiPickersUtilsProvider>
            ) : (
              ""
            )}
          </React.Fragment>
        ) : (
          ""
        )}
        <BlockBorder className="container">
          <BorderBackground />
        </BlockBorder>
        <FormRow>
          <AgreementInformationBlock>
            <Checkbox
              id="performRegistration"
              name="performRegistration"
              checked={performRegistration}
              className={сheckboxStyles.root}
              onChange={changePerformRegistration}
              inputProps={{ "aria-label": "primary checkbox" }}
              style={{
                color: "#4646eb",
                padding: 0,
              }}
            />
            <AgreementInformation>
              Hiermit bevollmächtige ich die homee GmbH, eine Anmeldung beim
              örtlichen Netzbetreiber durchzuführen und meinen bestehenden
              Vertrag mit meinem derzeitigen Lieferanten für den angegebenen
              Lieferpunkt zu kündigen.
            </AgreementInformation>
          </AgreementInformationBlock>
        </FormRow>
      </form>
    </Paper>
  );
};
