import React, { ReactElement, FunctionComponent } from "react";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useForm } from "react-hook-form";

import {
  PoliciesFormValues,
  ContractStep,
  ContractSteps,
} from "../../../types";
import { Paper } from "../../Paper";
import { SliderButtons } from "./SliderButons";
import { usePreviewData } from "./hooks/usePreviewData";

import { ButtonsContainer, SliderDot, EditButton } from "./styled";
import styles from "../../Carousel/styles.mod.scss";
import { useTrackStepEventByName } from "../hooks/useTrackStepEventByName";

interface PoliciesFormProps {
  isFormSubmitted: boolean;
  onGoToFormStep: (stepName: ContractStep, currentStep: number) => void;
  initValues: PoliciesFormValues;
  currentSlide: number;
  handleClickOpen: (
    agreementName: "agb" | "impressum" | "widerruf" | "datenschutz"
  ) => (
    event?:
      | React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
      | undefined
  ) => void;
}

export const PoliciesForm = ({
  isFormSubmitted,
  onGoToFormStep,
  initValues,
  currentSlide,
  handleClickOpen,
}: PoliciesFormProps): ReactElement => {
  useForm<PoliciesFormValues>({
    mode: "onChange",
    defaultValues: initValues,
  });

  useTrackStepEventByName(ContractSteps.policies);

  const previewData = usePreviewData();
  const getComponent = (SlideComponent: FunctionComponent, data: any) => {
    const componentProps = {
      ...data,
      handleClickOpen: handleClickOpen,
      isFormSubmitted: isFormSubmitted,
    };
    return <SlideComponent {...componentProps} />;
  };
  const getDots = () =>
    previewData.map((item, index) => {
      return (
        <SliderDot slide={index} key={index} className={styles.carousel__dot}>
          {""}
        </SliderDot>
      );
    });

  return (
    <Paper id="gestureZone">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={720}
        totalSlides={5}
        currentSlide={currentSlide}
        dragEnabled={false}
      >
        <Slider style={{ height: "720px", outline: "none" }}>
          {previewData.map((sliderItems, sliderIndex) => (
            <Slide key={sliderIndex} index={sliderIndex}>
              <EditButton
                onClick={() =>
                  onGoToFormStep(sliderItems.editComponent, sliderIndex)
                }
              />
              {/* @ts-ignore */}
              {getComponent(sliderItems.component, sliderItems.data)}
            </Slide>
          ))}
        </Slider>
        <ButtonsContainer>
          <div>{getDots()}</div>
          <SliderButtons />
        </ButtonsContainer>
      </CarouselProvider>
    </Paper>
  );
};
