import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setFormState } from "../../../../store/actions";
import { ContractForms, ContractFormValues } from "../../../../types";

export const useSubmitForm = (
  isValid: boolean,
  isFormSubmitted: boolean,
  getValues: () => ContractFormValues,
  contractStep: ContractForms
) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setFormState(contractStep, {
        isSubmitting: false,
        isValid: isValid,
      })
    );
  }, [isValid]);
};
