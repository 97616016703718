import React from "react";
import {
  PreviewTitle,
  PreviewRow,
  PreviewRowLeftSide,
  PreviewRowRightSide,
  BankName,
} from "./styled";

import { PaymentDetails } from "../Form/PaymentDetails";

export const BillingInfoPreview = (data: any) => {
  return (
    <div>
      <PreviewTitle>Zahlungsinformation</PreviewTitle>
      <PreviewRow>
        <PreviewRowLeftSide>Zahlungsart</PreviewRowLeftSide>
        <PreviewRowRightSide>{data.paymentType}</PreviewRowRightSide>
      </PreviewRow>
      {data.paymentType === "SEPA-Lastschrift" ? (
        <PreviewRow>
          <PreviewRowLeftSide>Vorname (Kontoinhaber)</PreviewRowLeftSide>
          <PreviewRowRightSide>{data.firstName}</PreviewRowRightSide>
        </PreviewRow>
      ) : (
        ""
      )}
      {data.surname.length && data.paymentType === "SEPA-Lastschrift" ? (
        <PreviewRow>
          <PreviewRowLeftSide>Nachname (Kontoinhaber)</PreviewRowLeftSide>
          <PreviewRowRightSide>{data.surname}</PreviewRowRightSide>
        </PreviewRow>
      ) : (
        ""
      )}
      {data.paymentType !== "SEPA-Lastschrift" ? (
        <PreviewRow>
          <PaymentDetails />
        </PreviewRow>
      ) : (
        <PreviewRow>
          <PreviewRowLeftSide>IBAN</PreviewRowLeftSide>
          <div>
            <PreviewRowRightSide>
              {data.iban?.match(/.{1,4}/g)?.join(" ")}
            </PreviewRowRightSide>
            <BankName>{data.bankName}</BankName>
          </div>
        </PreviewRow>
      )}
    </div>
  );
};
