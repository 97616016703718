export const StepsStaticData = {
  calculator: {
    activeButtons: [""],
    title: "Adresse komplett?",
    description:
      "Für den Vertragsabschluss brauchen wir deine vollständige Adresse. Also schau noch mal, ob alles stimmt.",
  },
  personalInformation: {
    activeButtons: ["personalInformation"],
    title: "Vertragsdaten",
    description:
      "Wir benötigen einige persönliche Angaben, um einen Vertrag für dich erstellen zu können.",
  },
  reasonForContract: {
    activeButtons: ["personalInformation", "reasonForContract"],
    title: "Grund des Wechsels",
    description: "Bitte wähle einen Grund für deinen Vertragsabschluss aus.",
  },
  switchOfSupplier: {
    activeButtons: [
      "personalInformation",
      "reasonForContract",
      "switchOfSupplier, relocation",
    ],
    title: "Anbieterwechsel",
    description: "",
  },
  relocation: {
    activeButtons: [
      "personalInformation",
      "reasonForContract",
      "switchOfSupplier, relocation",
      "policies",
    ],
    title: "Umzug",
    description: "",
  },
  policies: {
    activeButtons: [
      "personalInformation",
      "reasonForContract",
      "switchOfSupplier, relocation",
      "policies",
    ],
    title: "Angaben überprüfen",
    description:
      "Bitte überprüfe noch einmal alle Angaben für deinen neuen stromee Vertrag.",
  },
};

export const StepsAnalyticsEventData = {
  calculator: {
    view: {
      event: "contactSteps",
      stepName: "Adresse komplett",
      action: "Open",
      label: "Step Adresse komplett was opened",
    },
    close: {
      event: "contactSteps",
      stepName: "Adresse komplett",
      action: "Close",
      label: "Step Adresse komplett was closed",
    },
  },
  personalInformation: {
    view: {
      event: "contactSteps",
      stepName: "Vertragsdaten",
      action: "Open",
      label: "Step Vertragsdaten was opened",
    },
    close: {
      event: "contactSteps",
      stepName: "Vertragsdaten",
      action: "Close",
      label: "Step Vertragsdaten was closed",
    },
  },
  switchOfSupplier: {
    view: {
      event: "contactSteps",
      stepName: "Umzug",
      action: "Open",
      label: "Step Umzug was opened",
    },
    close: {
      event: "contactSteps",
      stepName: "Umzug",
      action: "Close",
      label: "Step Umzug was closed",
    },
  },
  relocation: {
    view: {
      event: "contactSteps",
      stepName: "Grund des Wechsels",
      action: "Open",
      label: "Step Grund des Wechsels was opened",
    },
    close: {
      event: "contactSteps",
      stepName: "Grund des Wechsels",
      action: "Close",
      label: "Step Grund des Wechsels was closed",
    },
  },
  policies: {
    view: {
      event: "contactSteps",
      stepName: "Angaben überprüfen",
      action: "Open",
      label: "Step Angaben überprüfen was opened",
    },
    close: {
      event: "contactSteps",
      stepName: "Angaben überprüfen",
      action: "Close",
      label: "Step Angaben überprüfen was closed",
    },
  },
  reasonForContract: {
    view: {
      event: "contactSteps",
      stepName: "Angaben überprüfen",
      action: "Open",
      label: "Step Angaben überprüfen was opened",
    },
    close: {
      event: "contactSteps",
      stepName: "Angaben überprüfen",
      action: "Close",
      label: "Step Angaben überprüfen was closed",
    },
  },
};

export const StepsButtonsStaticData = [
  {
    stepName: "personalInformation",
    stepTitle: "Schritt 1",
    isDisabled: true,
    isCurrent: false,
  },
  {
    stepName: "reasonForContract",
    stepTitle: "Schritt 2",
    isDisabled: true,
    isCurrent: false,
  },
  {
    stepName: "switchOfSupplier, relocation",
    stepTitle: "Schritt 3",
    isDisabled: true,
    isCurrent: false,
  },
  {
    stepName: "policies",
    stepTitle: "Schritt 4",
    isDisabled: true,
    isCurrent: false,
  },
];
