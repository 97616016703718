import React, { ReactElement, Ref, useEffect, useState } from "react";

import { Range } from "../../Form";
import { Button } from "../../Button";
import { Paper } from "../../Paper";

import {
  ContractSteps,
  ContractForms,
  EnergyCalculatorFormValues,
} from "../../../types";
import { useTariff } from "../../EnergyCalculator/hooks";
import {
  validateCity,
  validateStreet,
  validateHouseNumber,
  validateEnergyUsage,
  validatePostcode,
} from "../../EnergyCalculator/validation";
import { TextFieldCity, TextFieldStreet, TextField } from "../Form";
import { FormRow, PromoCodeWrapper } from "./styled";
import { useCalculatorData } from "./hooks";
import { usePromoCode } from "./hooks/usePromocode";
import { setFormState } from "../../../store/actions";
import { useDispatch } from "react-redux";
import { useTrackStepEventByName } from "../hooks/useTrackStepEventByName";
import { validatePromoCode } from "../validation";

interface CalculatorFormProps {
  initValues?: EnergyCalculatorFormValues;
  hasInternalSubmit: boolean;
  formRef: Ref<HTMLFormElement>;
  isFormSubmitted: boolean;
  isCalculatorEdited: boolean;
}

export const CalculatorForm = ({
  hasInternalSubmit,
  isFormSubmitted,
  isCalculatorEdited,
  initValues,
  formRef,
}: CalculatorFormProps): ReactElement => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { setTariff } = useCalculatorData(
    setIsSubmitted,
    isFormSubmitted,
    isCalculatorEdited
  );

  useTrackStepEventByName(ContractSteps.calculator);

  const {
    citiesPromise,
    cities,
    streetsPromise,
    streets,
    register,
    handleSubmit,
    errors,
    triggerValidation,
    clearError,
    energyUsageRange,
    cityId,
    street,
    promoCode,
    setValues,
    handleEnergyUsage,
    handleRange,
    onSubmit,
    formState,
    getValues,
    watch,
  } = useTariff(setTariff, initValues);
  const dispatch = useDispatch();
  const houseNumber = watch("houseNumber");
  const promoCodeData = usePromoCode(
    promoCode ? promoCode : "",
    triggerValidation
  );
  useEffect(() => {
    dispatch(
      setFormState(ContractForms.calculator, {
        isSubmitting: false,
        isValid: formState.isValid && Boolean(houseNumber),
      })
    );
  }, [formState.isValid, houseNumber]);
  const onStreetChange = () => {
    setValues([{ houseNumber: "" }]);
    clearError(["houseNumber"]);
  };
  const getCityName = (): string => {
    let result = "";

    if (!cities) {
      return result;
    }

    cities.data &&
      cities.data.forEach((city) => {
        if (Number(city.id) === Number(cityId)) {
          result = city.name;
        }
      });
    return result;
  };
  useEffect(() => {
    register({ name: "cityName", type: "custom" });
    setValues([{ cityName: getCityName() }]);
    setValues([
      { promoCodeDescription: promoCodeData.data?.description || "" },
    ]);
    if (isFormSubmitted || isCalculatorEdited) {
      onSubmit(getValues());
    }
  }, [isFormSubmitted, isCalculatorEdited, cities]);
  useEffect(() => {
    setValues([{ cityName: getCityName() }]);
  }, [cityId]);
  useEffect(() => {
    register({ name: "promoCodeDescription", type: "custom" });
  });
  return (
    <Paper>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <TextFieldCity
            id="postalCode"
            name="postalCode"
            type="number"
            inputProps={{ pattern: "d*", maxLength: 5 }}
            label="Postleitzahl"
            error={Boolean(
              errors.postalCode?.message || errors.cityId?.message
            )}
            helperText={errors.postalCode?.message || errors.cityId?.message}
            placeholder="Pflichtfeld"
            inputRef={register({
              validate: validatePostcode(citiesPromise),
            })}
            cities={cities}
            cityId={cityId ? cityId : ""}
            setCity={setValues}
            triggerValidation={triggerValidation}
          />
          <input
            className="d-none"
            name="cityId"
            type="text"
            ref={register({ validate: validateCity })}
          />
        </FormRow>
        <FormRow isStreet>
          <TextFieldStreet
            id="street"
            name="street"
            type="text"
            label="Straße"
            error={Boolean(errors.street?.message)}
            helperText={errors.street?.message}
            placeholder="Pflichtfeld"
            inputRef={register({
              validate: validateStreet(streetsPromise, true),
            })}
            inputProps={{ maxLength: 50 }}
            streets={streets}
            street={street ? street : ""}
            disabled={!cityId}
            setStreet={setValues}
            triggerValidation={triggerValidation}
            onStreetChange={onStreetChange}
            autoComplete="off"
          />
          <TextField
            id="houseNumber"
            name="houseNumber"
            type="text"
            label="Nr."
            error={Boolean(errors.houseNumber?.message)}
            helperText={errors.houseNumber?.message}
            inputProps={{ maxLength: 6 }}
            placeholder="Pflichtfeld"
            inputRef={register({
              validate: validateHouseNumber(
                Number(cityId),
                street ? street : "",
                true
              ),
            })}
            disabled={!street}
          />
        </FormRow>
        <FormRow isEnergyUsage>
          <TextField
            id="energyUsage"
            name="energyUsage"
            type="number"
            inputProps={{ pattern: "d*" }}
            helperText={errors.energyUsage?.message}
            label="kWh / Jahr"
            error={Boolean(errors.energyUsage?.message)}
            placeholder="Optional"
            onChange={handleEnergyUsage}
            inputRef={register({ validate: validateEnergyUsage })}
          />
        </FormRow>
        <FormRow>
          <Range
            id="energyUsageRange"
            name="energyUsageRange"
            label="Personen im Haushalt"
            min={1}
            max={5}
            value={Number(energyUsageRange)}
            onChange={handleRange}
            inputRef={register()}
          />
        </FormRow>
        <FormRow>
          <PromoCodeWrapper>
            <TextField
              id="promoCode"
              name="promoCode"
              type="text"
              label="Aktionscode"
              error={Boolean(errors.promoCode?.message)}
              helperText={errors.promoCode?.message}
              placeholder="Optional"
              inputRef={register({
                validate: validatePromoCode(Boolean(promoCodeData?.data?.code)),
              })}
              value={promoCode}
              onChange={(event) => {
                setValues([{ promoCode: event.target.value }], false);
              }}
            />
          </PromoCodeWrapper>
        </FormRow>
        {hasInternalSubmit && (
          <Button type="submit" disabled={formState.isSubmitting}>
            Berechnen
          </Button>
        )}
      </form>
    </Paper>
  );
};

CalculatorForm.defaultProps = {
  hasInternalSubmit: false,
};
