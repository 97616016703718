import React, { SyntheticEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { AppState, ContractForms, PoliciesFormValues } from "../../../types";
import { useSubmitForm } from "./hooks/useSubmitForm";
import {
  AgreementsLink,
  CheckboxComponent,
  PrivacyPolicyFormContainer,
  AgreementInformationContainer,
  CheckboxRow,
} from "./styled";

interface PrivacyPoliciesFormpProps {
  isFormSubmitted: boolean;
  handleClickOpen: (
    agreementName: "agb" | "impressum" | "widerruf" | "datenschutz"
  ) => (
    event?:
      | React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
      | undefined
  ) => void;
}

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

const PrivacyPolicyForm: React.FC<PrivacyPoliciesFormpProps> = ({
  handleClickOpen,
  isFormSubmitted,
}) => {
  const сheckboxStyles = useStyles();
  const { policies } = useSelector((state: AppState) => state.contract);
  const {
    register,
    formState,
    getValues,
    setValue,
    triggerValidation,
  } = useForm<PoliciesFormValues>({
    mode: "onChange",
    defaultValues: {
      termsAndConditions: policies.values.termsAndConditions,
      privacyPolicy: policies.values.privacyPolicy,
      cancellationPolicy: policies.values.cancellationPolicy,
    },
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const { isValid } = formState;
  const [isTermsAndConditions, setTermsAndConditions] = useState<boolean>(
    false
  );
  const [isPrivacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
  const [isCancellationPolicy, setCancellationPolicy] = useState<boolean>(
    false
  );

  useEffect(() => {
    register(
      { name: "termsAndConditions", type: "custom" },
      { required: true }
    );
    register({ name: "privacyPolicy", type: "custom" }, { required: true });
    register(
      { name: "cancellationPolicy", type: "custom" },
      { required: true }
    );
  }, []);

  useEffect(() => {
    setIsSubmitted(isFormSubmitted);
  }, [isFormSubmitted]);

  useSubmitForm(isValid, isSubmitted, getValues, ContractForms.policies);

  return (
    <PrivacyPolicyFormContainer>
      <p>Ich verstehe und bestätige die folgenden Dokumente: </p>
      <form>
        <CheckboxRow>
          <AgreementInformationContainer>
            <CheckboxComponent
              className={сheckboxStyles.root}
              id="termsAndConditions"
              name="termsAndConditions"
              checked={isTermsAndConditions}
              onChange={(event: SyntheticEvent<EventTarget>) => {
                const { checked } = event.target as HTMLInputElement;
                setTermsAndConditions(checked);
                setValue("termsAndConditions", checked);
                triggerValidation(["termsAndConditions"]);
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />

            <AgreementsLink href="#agb" onClick={handleClickOpen("agb")}>
              AGB
            </AgreementsLink>
          </AgreementInformationContainer>

          <AgreementInformationContainer>
            <CheckboxComponent
              className={сheckboxStyles.root}
              id="privacyPolicy"
              name="privacyPolicy"
              checked={isPrivacyPolicy}
              onChange={(event: SyntheticEvent<EventTarget>) => {
                const { checked } = event.target as HTMLInputElement;
                setPrivacyPolicy(checked);
                setValue("privacyPolicy", checked);
                triggerValidation(["privacyPolicy"]);
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <AgreementsLink
              href="#datenschutz"
              onClick={handleClickOpen("datenschutz")}
            >
              Datenschutz
            </AgreementsLink>
          </AgreementInformationContainer>

          <AgreementInformationContainer>
            <CheckboxComponent
              className={сheckboxStyles.root}
              id="cancellationPolicy"
              name="cancellationPolicy"
              checked={isCancellationPolicy}
              onChange={(event: SyntheticEvent<EventTarget>) => {
                const { checked } = event.target as HTMLInputElement;
                setCancellationPolicy(checked);
                setValue("cancellationPolicy", checked);
                triggerValidation(["cancellationPolicy"]);
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <AgreementsLink
              href="#widerruf"
              onClick={handleClickOpen("widerruf")}
            >
              Widerruf
            </AgreementsLink>
          </AgreementInformationContainer>
        </CheckboxRow>
      </form>
    </PrivacyPolicyFormContainer>
  );
};

export default PrivacyPolicyForm;
