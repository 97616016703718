import DateFnsAdapter from "@date-io/date-fns";
import addBusinessDays from "date-fns/addBusinessDays";

export const getDate = (date: Date): string => {
  const dateFns = new DateFnsAdapter();
  const initialDateFnsDate = dateFns.date(date);
  return dateFns.format(initialDateFnsDate, "yyyy-MM-dd");
};
export const compareDates = (dateFirst: Date, dateSecond: Date): boolean => {
  const dateFns = new DateFnsAdapter();
  return dateFns.isBefore(dateFirst, dateSecond);
};
export const datePlusYear = getDate(
  new Date(new Date().setFullYear(new Date().getFullYear() + 1))
);
export const dateMinusDays = getDate(
  new Date(new Date().setDate(new Date().getDate() - 42))
);
export const today = getDate(new Date());
export const datePlusBusinessDays = addBusinessDays(new Date(), 13);
export const getDatePlusDay = (date: string) => {
  const dateFns = new DateFnsAdapter();
  const initialDateFnsDate = dateFns.date(date);
  return getDate(
    new Date(
      new Date(initialDateFnsDate).setDate(initialDateFnsDate.getDate() + 1)
    )
  );
};
export const getInitialDate = (date: string | null | undefined) => {
  if (date == null) {
    return null;
  }
  const dateFns = new DateFnsAdapter();
  return dateFns.date(date);
};
