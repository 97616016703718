import styled from "styled-components";

interface FormRowProps {
  isStreet?: boolean;
  isEnergyUsage?: boolean;
}

export const FormRow = styled.div<FormRowProps>`
  display: flex;
  margin: 0 -14px 32px;

  ${({ isStreet }) =>
    isStreet &&
    `
    & > div:first-child {
      flex-basis: 60%
    }

    & > div:last-child {
      flex-basis: 40%
    }
  `}

  ${({ isEnergyUsage }) =>
    isEnergyUsage &&
    `
    margin-bottom: 44px;
  `}

  &:last-child {
    margin-bottom: 0;
  }

  & > div {
    margin: 0 14px;
  }
`;

export const PromoCodeWrapper = styled.div`
  width: 100%;
`;

export const PromoCodeDescription = styled.div`
  font-size: 12px;
  color: #abb3b5;
  margin-left: 16px;
  margin-top: 3px;
`;
