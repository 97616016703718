import styled from "styled-components";

export const PaymentInfo = styled.span`
  font-size: 14px;
  line-height: 1.36;
  color: #263242;
`;

export const PaymentInfoBold = styled(PaymentInfo)`
  font-weight: bold;
`;

export const PaymentInfoFirstBlock = styled.div`
  margin-bottom: 20px;
`;

export const CopyBlock = styled.div`
  display: flex;
`;

export const CopyIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 5px;
  cursor: pointer;
`;
