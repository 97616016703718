import { useEffect, useState } from "react";

import {
  BillingAddressFormValues,
  EnergyCalculatorFormValues,
} from "../../../../types";

export const useTariffData = (
  billingAddressInitValues: BillingAddressFormValues
): EnergyCalculatorFormValues => {
  const [tariffInitialData, setTariffInitialData] = useState({
    postalCode: "",
    cityId: "",
    street: "",
    houseNumber: "",
    cityName: "",
    energyUsage: "2500",
    energyUsageRange: "2",
    promoCode: "",
    promoCodeDescription: "",
  });
  useEffect(() => {
    const {
      billingZip,
      billingCity,
      billingHouseNumber,
      billingStreet,
      billingCityName,
    } = billingAddressInitValues;
    const tariffInitialData = {
      postalCode: billingZip,
      cityId: billingCity,
      street: billingStreet,
      houseNumber: billingHouseNumber,
      cityName: billingCityName,
      energyUsage: "2500",
      energyUsageRange: "2",
      promoCode: "",
      promoCodeDescription: "",
    };
    setTariffInitialData(tariffInitialData);
  }, [billingAddressInitValues]);
  return tariffInitialData;
};
