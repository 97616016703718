import React, { useContext, useEffect } from "react";
import { BackButton, NextButton } from "./styled";
import styles from "../../Carousel/styles.mod.scss";
import { ReactComponent as Arrow } from "../../../assets/arrow-left.svg";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentSlide } from "../../../store/actions/contractActions";
import { AppState } from "../../../types";
import { CarouselContext } from "pure-react-carousel";

export const SliderButtons: React.FC = () => {
  const { currentSlide } = useSelector((state: AppState) => state.contract);
  const dispatch = useDispatch();

  const carouselContext = useContext(CarouselContext);

  useEffect(() => {
    function onChange() {
      dispatch(setCurrentSlide(carouselContext.state.currentSlide));
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext, dispatch]);

  return (
    <div>
      <BackButton
        disabled={currentSlide === 0}
        className={styles.carousel__button_back}
      >
        <Arrow
          style={{
            transform: "rotate(180deg)",
          }}
          fillOpacity={currentSlide === 0 ? ".5" : "1"}
        />
      </BackButton>
      <NextButton
        disabled={currentSlide === 4}
        className={styles.carousel__button_next}
      >
        <Arrow fillOpacity={currentSlide === 4 ? ".5" : "1"} />
      </NextButton>
    </div>
  );
};
