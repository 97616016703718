import styled from "styled-components";

export const AgreementInformation = styled.p`
  font-size: 14px;
  color: #263242;
  margin: 0;
  padding-left: 16px;
`;

export const AgreementInformationContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const AgreementInformationBlock = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 32px !important;
`;

export const BlockBorder = styled.div`
  position: absolute;
  left: 0;
  height: 8px;
  padding: 0;
  @media (max-width: 992px) {
    padding: 0;
  }
`;
export const BorderBackground = styled.div`
  background-color: #e2e7e8;
  height: 8px;
`;
