import { useSelector } from "react-redux";

import { AppState, ContractStep } from "../../../../types";
import { PersonalInfoPreview } from "../PersonalInfoPreview";
import EnergyTariff from "../../../EnergyTariff";
import { BillingInfoPreview } from "../BillingInfoPreview";
import { ReasonForContractPreview } from "../ReasonForContractPreview";
import { BillingAlternativeAddress } from "../BillingAlternativeAddress";
import { getDatePlusDay } from "../../ReasonForContractForm/formattedDates";

export const usePreviewData = () => {
  const contract = useSelector((state: AppState) => state.contract);
  const {
    calculator,
    personalInformation,
    passedSteps,
    billingAddress,
  } = contract;
  let billingAlternativeData;
  if (billingAddress.isValid) {
    billingAlternativeData = {
      ...billingAddress.values,
      isBillingAlternativeData: true,
    };
  } else {
    billingAlternativeData = {
      ...calculator.values,
      isBillingAlternativeData: false,
    };
  }
  const getDesiredDate = () => {
    let desiredDate = "Nächstmöglicher Termin";
    if (
      contract.switchOfSupplier.values.desiredDate?.length &&
      contract.switchOfSupplier.values.contractCancel === "stromeeCancel"
    ) {
      desiredDate = contract.switchOfSupplier.values.desiredDate;
    } else {
      if (contract.switchOfSupplier.values.terminationDate?.length) {
        desiredDate = getDatePlusDay(
          contract.switchOfSupplier.values.terminationDate
        );
      }
    }
    return desiredDate;
  };

  const reasonForContract = passedSteps.includes("relocation")
    ? {
        ...contract.relocation.values,
        desiredDate: contract.relocation.values.relocationDate,
        reason: "Umzug",
        reasonName: "relocation" as ContractStep,
      }
    : {
        ...contract.switchOfSupplier.values,
        desiredDate: getDesiredDate(),
        reason: "Anbieterwechsel",
        reasonName: "switchOfSupplier" as ContractStep,
      };
  const {
    firstName,
    surname,
    iban,
    firstNameAccountHolder,
    lastNameAccountHolder,
    paymentType,
    bankName,
  } = personalInformation.values;
  const getBillingInfoData = () => {
    const billingInfoData = {
      firstName: firstName,
      surname: surname,
      iban: iban,
      paymentType: paymentType,
      bankName: bankName,
    };
    if (firstNameAccountHolder?.length || lastNameAccountHolder?.length) {
      billingInfoData.firstName =
        firstNameAccountHolder && firstNameAccountHolder.length
          ? firstNameAccountHolder
          : "";
      billingInfoData.surname =
        lastNameAccountHolder && lastNameAccountHolder.length
          ? lastNameAccountHolder
          : "";
    }
    return billingInfoData;
  };
  return [
    {
      component: EnergyTariff,
      data: {
        data: {
          tariff: calculator.tariff.data,
          formValues: calculator.values,
        },
        isPending: false,
        error: null,
        isPreview: true,
      },
      editComponent: "calculator" as ContractStep,
    },
    {
      component: PersonalInfoPreview,
      data: { ...personalInformation.values, ...calculator.values },
      editComponent: "personalInformation" as ContractStep,
    },
    {
      component: BillingInfoPreview,
      data: getBillingInfoData(),
      editComponent: "personalInformation" as ContractStep,
    },
    {
      component: BillingAlternativeAddress,
      data: billingAlternativeData,
      editComponent: "personalInformation" as ContractStep,
    },
    {
      component: ReasonForContractPreview,
      data: reasonForContract,
      editComponent: "reasonForContract" as ContractStep,
    },
  ];
};
