import React, { ReactElement } from "react";

import Layout from "../components/Contract/Layout";
import SEO from "../components/Seo";

const Contract = (): ReactElement => {
  return (
    <div>
      <SEO title="Contract" />
      <Layout />
    </div>
  );
};

export default Contract;
