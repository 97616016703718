import { useEffect, useState } from "react";
import { checkEmail } from "../../../../services";
import { createRequestState } from "../../../../utils";

export const useEmail = (email: string) => {
  const [emailResponse, setCheckEmail] = useState(createRequestState<false>());
  useEffect(() => {
    const fetchData = async () => {
      try {
        setCheckEmail({ isPending: true, data: null, error: null });
        const response = await checkEmail(email);
        setCheckEmail({ isPending: false, data: response.ok, error: null });
      } catch (error) {
        setCheckEmail({ isPending: false, data: null, error });
      }
    };
    if (email.length) {
      fetchData();
    }
  }, [email]);
  return emailResponse;
};
