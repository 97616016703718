import React, { ReactElement, useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { CalculatorForm } from "../CalculatorForm";
import Steps from "../Steps";
import Description from "../Description";
import ButtonsNextBack from "../ButtonsNextBack";
import {
  ContractStep,
  StepParameters,
  AppState,
  ContractForms,
  ContractState,
} from "../../../types";
import { StepsButtonsStaticData } from "../ContractStaticData";
import Logo from "../../../assets/logo-dark.svg";
import { ReactComponent as StepDisabledFirst } from "../../../assets/contract-step-disabled-first.svg";
import { ReactComponent as StepDisabledNext } from "../../../assets/contract-step-disabled-next.svg";
import { ReactComponent as StepActiveFirst } from "../../../assets/contract-step-active-first.svg";
import { ReactComponent as StepActiveNext } from "../../../assets/contract-step-active-next.svg";
import {
  LogoLink,
  LayoutContract,
  ContentContractLeftContainer,
  ContentContractRightContainer,
  ButtonsNextBackDesk,
  ButtonsNextBackMob,
  LayoutContainer,
  PreloaderContainerLayout,
} from "./styled";
import {
  goNextStep,
  setEditMode,
  setFormState,
  setCurrentSlide,
} from "../../../store/actions";
import { PersonalInformationForm } from "../PersonalInformationForm";
import { SwitchOfSupplierForm } from "../ReasonForContractForm/SwitchOfSupplierForm/SwitchOfSupplierForm";
import { RelocationForm } from "../ReasonForContractForm/RelocationForm";
import { ReasonForContract } from "../ReasonForContract";
import { PoliciesForm } from "../PoliciesForm";
import { useCreateContract } from "./hooks/useCreateContract";
import Preloader from "../../SVG/Preloader";
import { useModal } from "../../../hooks/useModal";
import { ModalContent } from "../PoliciesForm/ModalContent";
import { navigate } from "gatsby";
import PrivacyPolicyForm from "../PoliciesForm/PrivacyPolicyForm";

const getStepsButtonsList = (
  currentStep: ContractStep,
  contract: ContractState
): Array<StepParameters> =>
  StepsButtonsStaticData.map((step: StepParameters, index) => {
    step.isCurrent = false;
    const formStep = contract.passedSteps.find((stepItem) => {
      return step.stepName.includes(stepItem);
    });
    if (!formStep) {
      step.isDisabled = true;
      step.isCurrent = true;
      index === 0
        ? (step.background = StepDisabledFirst)
        : (step.background = StepDisabledNext);
    } else {
      if (step.stepName.includes(currentStep)) {
        step.isCurrent = true;
      }
      step.isDisabled = false;
      index === 0
        ? (step.background = StepActiveFirst)
        : (step.background = StepActiveNext);
    }
    return step;
  });

const Contract = (): ReactElement => {
  const { open, agreementContent, handleClickOpen, handleClose } = useModal();
  const calculatorFormRef = useRef<HTMLFormElement>(null);
  const dispatch = useDispatch();
  const contract = useSelector((state: AppState) => state.contract);
  const { currentStep, passedSteps, currentSlide, isPreloader } = contract;
  let currentForm;

  useEffect(() => {
    setIsFormSubmitted(false);
    setIsCalculatorSubmitted(false);
    setIsCalculatorEdited(false);
    setIsFormEdited(false);
  }, [currentStep, passedSteps]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep, passedSteps]);
  switch (currentStep) {
    case "calculator":
      currentForm = contract[ContractForms.calculator];
      break;
    case "personalInformation":
      currentForm = contract[ContractForms.personalInformation];
      break;
    case "relocation":
      currentForm = contract[ContractForms.relocation];
      break;
    case "switchOfSupplier":
      currentForm = contract[ContractForms.switchOfSupplier];
      break;
    case "policies":
      currentForm = contract[ContractForms.policies];
      break;

    default:
      break;
  }

  const StepsButtonsList = getStepsButtonsList(currentStep, contract);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [isCalculatorSubmitted, setIsCalculatorSubmitted] = useState(false);
  const [isCalculatorEdited, setIsCalculatorEdited] = useState(false);
  const [isContractSubmitted, setContractSubmitted] = useState(false);

  const onSwitchToStep = (stepName: string) => {
    const formStep = passedSteps.find((step) => {
      return stepName.includes(step);
    });
    formStep && dispatch(goNextStep(formStep));
  };

  const getButtonName = () => {
    if (
      contract.passedSteps.includes("policies") &&
      currentStep !== "policies"
    ) {
      return "Zur Übersicht";
    }
    return currentStep === "policies" ? "Auftrag senden" : "Weiter";
  };

  const onClickNext = () => {
    switch (currentStep) {
      case "calculator":
        contract.passedSteps.includes("policies")
          ? setIsCalculatorEdited(true)
          : setIsCalculatorSubmitted(true);
        break;
      case "personalInformation":
        contract.passedSteps.includes("policies")
          ? setIsFormEdited(true)
          : setIsFormSubmitted(true);
        break;
      case "relocation":
        contract.passedSteps.includes("policies")
          ? setIsFormEdited(true)
          : setIsFormSubmitted(true);
        break;
      case "switchOfSupplier":
        contract.passedSteps.includes("policies")
          ? setIsFormEdited(true)
          : setIsFormSubmitted(true);
        break;
      case "policies":
        setContractSubmitted(true);
        break;
      default:
        break;
    }
  };
  const contractResponse = useCreateContract(isContractSubmitted);

  useEffect(() => {
    setContractSubmitted(false);
  }, [contractResponse]);
  const onClickBack = () => {
    const { isEditMode } = contract;
    const { previousStep } = contract[currentStep];
    const previousStepName = contract.passedSteps.find((stepItem) => {
      return previousStep.includes(stepItem);
    });
    if (currentStep === "calculator") {
      isEditMode ? dispatch(goNextStep("policies")) : navigate("/#calculate");
      return;
    }
    if (
      contract.passedSteps.includes("policies") &&
      currentStep !== "policies" &&
      isEditMode
    ) {
      dispatch(goNextStep("policies"));
      return;
    }
    previousStepName && dispatch(goNextStep(previousStepName));
    dispatch(setEditMode(false));
  };

  const onClickChangeOfProvider = () => {
    dispatch(goNextStep("switchOfSupplier"));
    setFormState(ContractForms.reasonForContract, {
      isSubmitting: false,
      isValid: true,
    });
  };

  const onClickRelocation = () => {
    dispatch(goNextStep("relocation"));
    setFormState(ContractForms.reasonForContract, {
      isSubmitting: false,
      isValid: true,
    });
  };

  const onGoToFormStep = (stepName: ContractStep, currentSlide: number) => {
    dispatch(setEditMode(true));
    dispatch(setCurrentSlide(currentSlide));
    dispatch(goNextStep(stepName));
  };

  const getForm = () => {
    switch (currentStep) {
      case "calculator":
        return (
          <CalculatorForm
            formRef={calculatorFormRef}
            isFormSubmitted={isCalculatorSubmitted}
            isCalculatorEdited={isCalculatorEdited}
            initValues={contract[ContractForms.calculator]?.values}
          />
        );
      case "personalInformation":
        return (
          <PersonalInformationForm
            isFormSubmitted={isFormSubmitted}
            isFormEdited={isFormEdited}
            initValues={contract[ContractForms.personalInformation]?.values}
            billingAddressInitValues={
              contract[ContractForms.billingAddress]?.values
            }
          />
        );
      case "reasonForContract":
        return (
          <ReasonForContract
            onClickChangeOfProvider={onClickChangeOfProvider}
            onClickRelocation={onClickRelocation}
          />
        );
      case "relocation":
        return (
          <RelocationForm
            isFormEdited={isFormEdited}
            isFormSubmitted={isFormSubmitted}
            initValues={contract[ContractForms.relocation]?.values}
          />
        );
      case "switchOfSupplier":
        return (
          <SwitchOfSupplierForm
            isFormSubmitted={isFormSubmitted}
            isFormEdited={isFormEdited}
            initValues={contract[ContractForms.switchOfSupplier]?.values}
          />
        );
      case "policies":
        return (
          <PoliciesForm
            isFormSubmitted={isFormSubmitted}
            onGoToFormStep={onGoToFormStep}
            currentSlide={currentSlide}
            initValues={contract[ContractForms.policies]?.values}
            handleClickOpen={handleClickOpen}
          />
        );
      default:
        return "";
    }
  };

  return (
    <LayoutContainer>
      <LayoutContract className="container">
        <ContentContractLeftContainer>
          <LogoLink href="/">
            <img
              alt="Logo"
              style={{ width: "100%", height: "100%" }}
              src={Logo}
            />
          </LogoLink>
          <Steps
            StepsButtonsList={StepsButtonsList}
            onSwitchToStep={onSwitchToStep}
          />
          <Description currentStep={currentStep} />

          <ButtonsNextBackDesk>
            {currentStep === "policies" && (
              <PrivacyPolicyForm
                handleClickOpen={handleClickOpen}
                isFormSubmitted={isFormSubmitted}
              />
            )}
            <ButtonsNextBack
              isDisabled={!currentForm?.isValid}
              isBackVisible={
                !!currentStep || contract.passedSteps.includes("policies")
              }
              onClickNext={onClickNext}
              onClickBack={onClickBack}
              buttonName={getButtonName()}
            />
          </ButtonsNextBackDesk>
        </ContentContractLeftContainer>
        <ContentContractRightContainer>
          {getForm()}
          <ButtonsNextBackMob>
            {currentStep === "policies" && (
              <PrivacyPolicyForm
                handleClickOpen={handleClickOpen}
                isFormSubmitted={isFormSubmitted}
              />
            )}
            <ButtonsNextBack
              isDisabled={!currentForm?.isValid}
              isBackVisible={
                !!currentStep || contract.passedSteps.includes("policies")
              }
              buttonName={getButtonName()}
              onClickNext={onClickNext}
              onClickBack={onClickBack}
            />
          </ButtonsNextBackMob>
        </ContentContractRightContainer>
      </LayoutContract>
      {isPreloader ? (
        <PreloaderContainerLayout>
          <Preloader />
        </PreloaderContainerLayout>
      ) : (
        ""
      )}
      <ModalContent
        open={open}
        agreementContent={agreementContent}
        handleClose={handleClose}
      />
    </LayoutContainer>
  );
};

export default Contract;
