import React, { ReactElement } from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";

interface ModalViewProps {
  handleClose: () => void;
  agreementContent: ReactElement;
  open: boolean;
}

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const ModalContent = (props: ModalViewProps) => {
  const { handleClose, agreementContent, open } = props;
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {agreementContent}
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};
