import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setFormState, setFormValues } from "../../../../store/actions";
import {
  EnergyCalculatorFormValues,
  BillingAddressFormValues,
  ContractForms,
  AppState,
} from "../../../../types";

export const useBillingAddressState = (
  isFormSubmitted: boolean,
  getAddressValues: () => EnergyCalculatorFormValues,
  getModalValues: () => BillingAddressFormValues,
  resetBillingForm: (data: any) => void,
  reset: (data: any) => void
) => {
  const contract = useSelector((state: AppState) => state.contract);
  const {
    calculator: {
      cities: { data },
    },
  } = contract;
  const dispatch = useDispatch();
  useEffect(() => {
    const addressValues = getAddressValues();
    if (isFormSubmitted) {
      const modalValues = getModalValues();

      const city = (data || []).find((cityItem) => {
        return cityItem.id === addressValues.cityId;
      });
      modalValues.billingZip = addressValues.postalCode;
      modalValues.billingCity = addressValues?.cityId || "";
      modalValues.billingCityName = city?.name || "";
      modalValues.billingStreet = addressValues?.street || "";
      modalValues.billingHouseNumber = addressValues?.houseNumber || "";
      resetBillingForm(modalValues);
      reset(addressValues);
      dispatch(setFormValues(ContractForms.billingAddress, { ...modalValues }));
      dispatch(
        setFormState(ContractForms.billingAddress, {
          isSubmitting: true,
          isValid: true,
        })
      );
    }
  }, [isFormSubmitted, getAddressValues, getModalValues]);
};
