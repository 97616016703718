import styled from "styled-components";
import { KeyboardDatePicker } from "@material-ui/pickers";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

import { Button } from "../../Button";
import { TextFieldStyle } from "../styled";

export const DatePicker = styled(KeyboardDatePicker)`
  ${TextFieldStyle}
`;

export const FormTitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 25px;
`;

export const FormTitleIban = styled(FormTitleContainer)`
  margin-bottom: 0;
`;

export const FormTitle = styled.p`
  font-size: 22px;
  font-weight: bold;
  color: #4646eb;
  margin: 0 0 0 12px;
`;

export const BillingAddressContainer = styled.div`
  border-radius: 16px;
  background-color: #f6f9fa;
  padding: 16px;
  margin-bottom: 32px;
`;

export const BillingAddressTitle = styled.span`
  font-size: 14px;
  color: #abb3b5;
  display: block;
  margin-bottom: 5px;
`;

export const BillingAddressValue = styled.span`
  font-size: 16px;
  color: #263242;
  display: block;
  margin-bottom: 16px;
`;

export const BillingInformation = styled.p`
  font-size: 14px;
  color: #263242;
  margin: 0;
  padding-left: 16px;
`;

export const BillingInformationContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const buttonsStyles = {
  height: "60px",
  borderRadius: "30px",
  maxWidth: "451px",
  width: "100%",
  margin: "24px auto 0 auto",
  display: "block",
  backgroundColor: "#ffffff",
  color: "#4646eb",
  border: "1px solid #abb3b5",
  "&:hover": {
    backgroundColor: "#abb3b5",
  },
};

export const BillingAddressButton = styled(Button)`
  height: 60px;
  border-radius: 30px;
  max-width: 451px;
  width: 100%;
  margin: 24px auto 0 auto;
  display: block;
  background-color: #ffffff;
  color: #4646eb;
  border: 1px solid #abb3b5;
  white-space: nowrap;
  &:hover: {
    background-color: #abb3b5;
  }
  @media (max-width: 767px) {
    padding: 20px 2px;
  }
`;

export const ModalContent = styled(MuiDialogContent)`
  padding: 42px 36px;

  & .MuiPaper-root {
    border-radius: 18px;
    box-shadow: 0 0 26px 0 rgba(10, 31, 68, 0.12);
  }
`;

export const ModalContainer = styled(Dialog)`
  & .MuiPaper-root {
    padding: 0 16px 22px 16px;
    border-radius: 18px;
    box-shadow: 0 0 26px 0 rgba(10, 31, 68, 0.12);
  }
  & .MuiDialogContent-dividers {
    border: none;
  }
  @media (max-width: 767px) {
    & .MuiDialogContent-root {
      overflow: hidden;
      padding: 0;
    }
  }
`;

export const ModalTitle = styled.h2`
  margin: 24px 0;
  font-size: 22px;
  font-weight: bold;
  color: #263242;
  @media (max-width: 767px) {
    font-size: 18px;
    margin: 12px 0;
  }
`;

export const ModalButton = styled(Button)`
  width: 80%;
  max-width: 328px;
  margin: 48px auto 0 auto;
  display: block;
  font-size: 17px;
  font-weight: bold;
  padding: 20px 27px;
  @media (max-width: 767px) {
    width: 100%;
    padding: 10px;
  }
`;

export const CloseButtonWrapper = styled.div`
  cursor: pointer;
  width: 42px;
  height: 42px;
  @media (max-width: 767px) {
    width: 35px;
    height: 35px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IbanWrapper = styled.div`
  width: 100%;
`;

export const BankName = styled.div`
  font-size: 12px;
  color: #abb3b5;
  margin-left: 16px;
  margin-top: 3px;
`;

export const ClearButton = styled.div`
  cursor: pointer;
  color: #abb3b5;
  margin-top: 16px;
  font-weight: bold;
  text-align: center;
`;

export const PaymentInfo = styled.span`
  font-size: 14px;
  line-height: 1.36;
  color: #263242;
`;

export const PaymentInfoBold = styled(PaymentInfo)`
  font-weight: bold;
`;

export const PaymentInfoFirstBlock = styled.div`
  margin-bottom: 20px;
`;

export const CopyIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 5px;
  cursor: pointer;
`;

export const CopyBlock = styled.div`
  display: flex;
`;
