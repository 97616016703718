import React from "react";
import { formatDate } from "../utils";

import {
  PreviewTitle,
  PreviewRow,
  PreviewRowLeftSide,
  PreviewRowRightSide,
} from "./styled";

export const PersonalInfoPreview = (data: any) => {
  return (
    <div>
      <PreviewTitle>Persönliche Daten</PreviewTitle>
      <PreviewRow>
        <PreviewRowLeftSide>Vorname</PreviewRowLeftSide>
        <PreviewRowRightSide>{data.firstName}</PreviewRowRightSide>
      </PreviewRow>
      <PreviewRow>
        <PreviewRowLeftSide>Nachname</PreviewRowLeftSide>
        <PreviewRowRightSide>{data.surname}</PreviewRowRightSide>
      </PreviewRow>
      <PreviewRow>
        <PreviewRowLeftSide>Postleitzahl</PreviewRowLeftSide>
        <PreviewRowRightSide>{data.postalCode}</PreviewRowRightSide>
      </PreviewRow>
      <PreviewRow>
        <PreviewRowLeftSide>Adresse</PreviewRowLeftSide>
        <PreviewRowRightSide>
          {data.cityName}, {data.street}, {data.houseNumber}
        </PreviewRowRightSide>
      </PreviewRow>
      <PreviewRow>
        <PreviewRowLeftSide>Geburtsdatum</PreviewRowLeftSide>
        <PreviewRowRightSide>
          {formatDate(data.dateOfBirth)}
        </PreviewRowRightSide>
      </PreviewRow>
      <PreviewRow>
        <PreviewRowLeftSide>E-Mail</PreviewRowLeftSide>
        <PreviewRowRightSide>{data.emailPrivate}</PreviewRowRightSide>
      </PreviewRow>
      <PreviewRow>
        <PreviewRowLeftSide>Telefonnummer</PreviewRowLeftSide>
        <PreviewRowRightSide>
          {data.phoneMobileAreaCode}
          {data.phoneMobile}
        </PreviewRowRightSide>
      </PreviewRow>
    </div>
  );
};
