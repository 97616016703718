import styled, { css } from "styled-components";

import { Button } from "../../Button";

const commonButtonStyles = css`
  width: 48%;
  max-width: 220px;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 345px;
    margin: 0 auto;
  }
`;

export const ButtonBack = styled(Button)`
  ${commonButtonStyles}
  background-color: #ffffff;
  color: #4646eb;
  border: 2px solid #abb3b5;
  margin-right: 17px;
  @media (max-width: 992px) and (min-width: 768px) {
    margin-bottom: 44px;
  }
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

export const ButtonNext = styled(Button)`
  ${commonButtonStyles}
  @media (max-width: 767px) {
    margin-top: 24px;
    margin-bottom: 16px;
  }
  @media (max-width: 992px) and (min-width: 768px) {
    margin-bottom: 44px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-start;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;
