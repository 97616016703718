import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createRequestState } from "../../../../utils";
import {
  EnergyTariffValues,
  SetState,
  RequestState,
  ContractForms,
  AppState,
} from "../../../../types";
import {
  goNextStep,
  setFormValues,
  setTariffState,
} from "../../../../store/actions";

interface ReturnType {
  setTariff: SetState<RequestState<EnergyTariffValues>>;
}

export const useCalculatorData = (
  setIsSubmitted: (isSubmitted: boolean) => void,
  isFormSubmitted: boolean,
  isCalculatorEdited: boolean
): ReturnType => {
  const [tariff, setTariff] = useState(
    createRequestState<EnergyTariffValues>()
  );
  const contract = useSelector((state: AppState) => state.contract);
  const { calculator } = contract;
  const dispatch = useDispatch();

  useEffect(() => {
    if (tariff.data?.formValues) {
      dispatch(
        setFormValues(ContractForms.calculator, tariff.data?.formValues)
      );
    }
  }, [dispatch, tariff.data?.formValues]);

  useEffect(() => {
    if (tariff.data?.tariff) {
      dispatch(
        setTariffState({
          isPending: tariff.isPending,
          data: tariff.data?.tariff || null,
          error: tariff.error,
        })
      );
    }

    if (isFormSubmitted && calculator.tariff.data && !tariff.isPending) {
      dispatch(goNextStep("personalInformation"));
    }
    if (isCalculatorEdited && calculator.tariff.data && !tariff.isPending) {
      dispatch(goNextStep("policies"));
    }
  }, [
    dispatch,
    tariff.data?.tariff,
    tariff.error,
    tariff.isPending,
    calculator.tariff.data,
  ]);

  return { setTariff };
};
