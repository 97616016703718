import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState, ContractForms } from "../../../../types";
import { setFormValues } from "../../../../store/actions";

export const useBillingAddressData = () => {
  const contract = useSelector((state: AppState) => state.contract);
  const {
    calculator: {
      values: { cityId, houseNumber, postalCode, street, cityName },
    },
    billingAddress: {
      isValid: isBillingAddressValid,
      values: billingAddressValues,
    },
  } = contract;
  const billingAddressData = {
    ...billingAddressValues,
    isBillingAlternativeAddress: isBillingAddressValid,
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isBillingAddressValid) {
      billingAddressData.billingZip = postalCode;
      billingAddressData.billingCity = cityId || "";
      billingAddressData.billingCityName = cityName || "";
      billingAddressData.billingStreet = street || "";
      billingAddressData.billingHouseNumber = houseNumber || "";
      dispatch(
        setFormValues(ContractForms.billingAddress, { ...billingAddressData })
      );
    }
  }, [isBillingAddressValid]);
  return billingAddressData;
};
