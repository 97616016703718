import styled from "styled-components";

export const Title = styled.h1`
  font-size: 48px;
  color: #263242;
  font-weight: bold;
  margin-top: 60px;
  margin-bottom: 24px;
  @media (max-width: 767px) {
    font-size: 28px;
    margin-top: 50px;
    margin-bottom: 16px;
  }
`;

export const Description = styled.p`
  font-size: 24px;
  color: #263242;
  margin-bottom: 44px;
  @media (max-width: 767px) {
    font-size: 16px;
    margin-bottom: 32px;
  }
`;
