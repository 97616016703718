import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { ReactComponent as CloseIcon } from "../../../assets/close.svg";
import { useBillingAddressState } from "./hooks/useBillingAddressState";
import {
  ModalContent,
  ModalContainer,
  ModalTitle,
  ModalButton,
  CloseButtonWrapper,
  ModalHeader,
} from "./styled";
import { AppState, BillingAddressFormValues } from "../../../types";
import { FormRow } from "../CalculatorForm/styled";
import { TextField, TextFieldCity, TextFieldStreet } from "../Form";
import {
  validateAreaCode,
  validateBillingEmail,
  validateFirstName,
  validateLastName,
  validatePhoneNumber,
} from "../validation";
import {
  validateCity,
  validateHouseNumber,
  validatePostcode,
  validateStreet,
} from "../../EnergyCalculator/validation";
import { useTariff } from "../../EnergyCalculator/hooks";
import { useTariffData } from "./hooks/useTariffData";
import { useSelector } from "react-redux";

interface BillingAddressModalProps {
  isOpen: boolean;
  handleClose: () => void;
  billingAddressInitValues: BillingAddressFormValues;
}

const calculatorDefaultValues = {
  cityId: " ",
  cityName: "",
  energyUsage: "2500",
  energyUsageRange: "2",
  houseNumber: "",
  postalCode: "",
  street: "",
  promoCode: "",
  promoCodeDescription: "",
};

const billingAddressDefaultValues = {
  billingFirstName: "",
  billingSurname: "",
  billingZip: "",
  billingCity: "",
  billingCityName: "",
  billingStreet: "",
  billingHouseNumber: "",
  billingEmail: "",
  billingPhoneAreaCode: "",
  billingPhone: "",
};

export const BillingAddressModal: React.FC<BillingAddressModalProps> = ({
  isOpen,
  handleClose,
  billingAddressInitValues,
}: BillingAddressModalProps) => {
  const {
    register: registerBillingForm,
    errors: errorsBillingForm,
    formState: stateBillingForm,
    getValues: getBillingFormValues,
    reset: resetBillingForm,
  } = useForm<BillingAddressFormValues>({
    mode: "onChange",
    defaultValues: billingAddressInitValues,
  });
  const tariffInitialData = useTariffData(billingAddressInitValues);
  const {
    register,
    errors,
    triggerValidation,
    citiesPromise,
    cities,
    streetsPromise,
    streets,
    cityId,
    street,
    setValues,
    formState,
    getValues,
    reset,
    clearError,
  } = useTariff(undefined, tariffInitialData);
  const contract = useSelector((state: AppState) => state.contract);
  const {
    billingAddress: { isValid: isBillingAddressValid },
  } = contract;
  const [isModalSubmit, setIsModalSubmit] = useState(false);
  const onStreetChange = () => {
    setValues([{ houseNumber: "" }]);
    clearError(["houseNumber"]);
  };
  useEffect(() => {
    if (isOpen && isBillingAddressValid) {
      resetBillingForm(billingAddressInitValues);
      reset(tariffInitialData, {
        isValid: true,
      });
      triggerValidation(["postalCode", "street", "houseNumber", "energyUsage"]);
    }
    if (isOpen && !isBillingAddressValid) {
      resetBillingForm(billingAddressDefaultValues);
      reset(calculatorDefaultValues, {
        isValid: true,
      });
    }
    setIsModalSubmit(false);
  }, [isOpen, reset, tariffInitialData, billingAddressInitValues]);
  useEffect(() => {
    if (isBillingAddressValid) {
      triggerValidation(["postalCode", "street", "houseNumber", "energyUsage"]);
    }
  }, [formState.isValid]);
  useBillingAddressState(
    isModalSubmit,
    getValues,
    getBillingFormValues,
    resetBillingForm,
    reset
  );
  return (
    <ModalContainer
      scroll="body"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <ModalContent dividers>
        <ModalHeader>
          <ModalTitle>Abweichende Rechnungsadresse</ModalTitle>
          <CloseButtonWrapper>
            <CloseIcon
              style={{ width: "100%", height: "100%" }}
              preserveAspectRatio="xMinYMin meet"
              viewBox="0 0 42 42"
              onClick={handleClose}
            />
          </CloseButtonWrapper>
        </ModalHeader>
        <form>
          <FormRow>
            <TextField
              id="billingFirstName"
              name="billingFirstName"
              type="text"
              label="Vorname"
              error={Boolean(errorsBillingForm.billingFirstName?.message)}
              helperText={errorsBillingForm.billingFirstName?.message}
              placeholder="Pflichtfeld"
              inputRef={registerBillingForm({ validate: validateFirstName })}
              inputProps={{ maxLength: 30 }}
            />
          </FormRow>
          <FormRow>
            <TextField
              id="billingSurname"
              name="billingSurname"
              type="text"
              label="Nachname"
              error={Boolean(errorsBillingForm.billingSurname?.message)}
              helperText={errorsBillingForm.billingSurname?.message}
              placeholder="Pflichtfeld"
              inputRef={registerBillingForm({ validate: validateLastName })}
              inputProps={{ maxLength: 30 }}
            />
          </FormRow>
          <FormRow>
            <TextFieldCity
              id="postalCode"
              name="postalCode"
              type="number"
              inputProps={{ pattern: "d*", maxLength: 5 }}
              label="Postleitzahl"
              error={Boolean(
                errors.postalCode?.message || errors.cityId?.message
              )}
              helperText={errors.postalCode?.message || errors.cityId?.message}
              placeholder="Pflichtfeld"
              inputRef={register({
                validate: validatePostcode(citiesPromise),
              })}
              cities={cities}
              cityId={cityId || ""}
              setCity={setValues}
              triggerValidation={triggerValidation}
            />
            <input
              className="d-none"
              name="cityId"
              type="text"
              ref={register({ validate: validateCity })}
            />
          </FormRow>
          <FormRow>
            <TextFieldStreet
              id="street"
              name="street"
              type="text"
              label="Straße"
              autoComplete="off"
              error={Boolean(errors.street?.message)}
              helperText={errors.street?.message}
              placeholder="Pflichtfeld"
              inputProps={{ maxLength: 50 }}
              inputRef={register({
                validate: validateStreet(streetsPromise, true),
              })}
              streets={streets}
              street={street || ""}
              disabled={!cityId}
              setStreet={setValues}
              triggerValidation={triggerValidation}
              onStreetChange={onStreetChange}
            />
            <TextField
              id="houseNumber"
              name="houseNumber"
              type="text"
              label="Nr."
              error={Boolean(errors.houseNumber?.message)}
              helperText={errors.houseNumber?.message}
              placeholder="Pflichtfeld"
              inputProps={{ maxLength: 6 }}
              inputRef={register({
                validate: validateHouseNumber(
                  Number(cityId),
                  street || "",
                  true
                ),
              })}
              disabled={!street}
            />
          </FormRow>
          <FormRow>
            <TextField
              id="billingEmail"
              name="billingEmail"
              type="text"
              label="E-Mail"
              error={Boolean(errorsBillingForm.billingEmail?.message)}
              helperText={errorsBillingForm.billingEmail?.message}
              placeholder="Pflichtfeld"
              inputRef={registerBillingForm({
                validate: validateBillingEmail(),
              })}
            />
          </FormRow>
          <FormRow>
            <TextField
              id="billingPhoneAreaCode"
              name="billingPhoneAreaCode"
              type="text"
              label="Vorwahl"
              style={{ width: "159px", marginRight: 0 }}
              error={Boolean(errorsBillingForm.billingPhoneAreaCode?.message)}
              helperText={errorsBillingForm.billingPhoneAreaCode?.message}
              placeholder="Pflichtfeld"
              inputRef={registerBillingForm({ validate: validateAreaCode })}
              inputProps={{ maxLength: 6 }}
            />
            <TextField
              id="billingPhone"
              name="billingPhone"
              type="text"
              label="Telefonnummer"
              error={Boolean(errorsBillingForm.billingPhone?.message)}
              helperText={errorsBillingForm.billingPhone?.message}
              placeholder="Pflichtfeld"
              inputRef={registerBillingForm({ validate: validatePhoneNumber })}
              inputProps={{ maxLength: 15 }}
            />
          </FormRow>
        </form>
        <ModalButton
          onClick={() => {
            setIsModalSubmit(true);
            handleClose();
          }}
          disabled={!(stateBillingForm.isValid && formState.isValid)}
        >
          Rechnungsadresse bestätigen
        </ModalButton>
      </ModalContent>
    </ModalContainer>
  );
};
