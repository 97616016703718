import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@reach/router";

import { AppState, RequestState } from "../../../../types";
import { createRequestState } from "../../../../utils";
import { createContract } from "../../../../services";
import { setIsPreloader } from "../../../../store/actions";

export const useCreateContract = (
  isFormSubmitted: boolean
): RequestState<true> => {
  const [contractResponse, setContract] = useState(createRequestState<true>());
  const contract = useSelector((state: AppState) => state.contract);
  const dispatch = useDispatch();
  useEffect(() => {
    const { passedSteps } = contract;
    const promoCode = contract.calculator.values.promoCode?.length
      ? {
          promoCode: contract.calculator.values.promoCode,
        }
      : {};
    const meteringNumberRelocation = contract.relocation.values.meteringNumber
      ?.length
      ? { meteringNumber: contract.relocation.values.meteringNumber }
      : {};
    const meteringNumberSwitchOfSupplier = contract.switchOfSupplier.values
      ?.meteringNumber?.length
      ? {
          meteringNumber: contract.switchOfSupplier.values?.meteringNumber,
        }
      : {};
    const desiredDate =
      contract.switchOfSupplier.values?.desiredDate?.length &&
      !contract.switchOfSupplier.values?.terminationDate?.length
        ? {
            desiredDate: contract.switchOfSupplier.values?.desiredDate,
          }
        : {};
    const terminationDate = contract.switchOfSupplier.values?.terminationDate
      ?.length
      ? {
          terminationDate: contract.switchOfSupplier.values?.terminationDate,
        }
      : {};
    const customerSpecification = !contract.switchOfSupplier.values
      ?.terminationDate?.length
      ? {
          customerSpecification:
            contract.switchOfSupplier.values?.customerSpecification,
        }
      : { customerSpecification: "terminated_in_person" };
    const {
      firstNameAccountHolder,
      lastNameAccountHolder,
      iban,
      ...personalData
    } = contract.personalInformation.values;
    const accountHolder =
      firstNameAccountHolder?.length || lastNameAccountHolder?.length
        ? {
            alternativeAccountHolder: `${firstNameAccountHolder} ${lastNameAccountHolder}`,
          }
        : {};
    const ibanNumber = iban?.length ? { iban: iban.replace(/\s/g, "") } : {};
    const reasonForContract = passedSteps.includes("relocation")
      ? {
          relocationDate: contract.relocation.values.relocationDate,
          customerSpecification: "relocation_at",
          ...meteringNumberRelocation,
        }
      : {
          ...meteringNumberSwitchOfSupplier,
          ...desiredDate,
          ...terminationDate,
          terminationDate: contract.switchOfSupplier.values.terminationDate,
          previousProviderCodeNumber:
            contract.switchOfSupplier.values.previousProviderCodeNumber,
          ...customerSpecification,
        };
    const billingAddressData = contract.billingAddress.isValid
      ? {
          ...contract.billingAddress.values,
          billingAlternativeAddress: true,
          billingZip: Number(contract.billingAddress.values.billingZip),
        }
      : {};
    const contractData = {
      ...personalData,
      ...accountHolder,
      ...ibanNumber,
      ...{
        zip: parseInt(contract.calculator.values.postalCode),
        city: contract.calculator.values.cityName || "",
        street: contract.calculator.values.street || "",
        houseNumber: contract.calculator.values.houseNumber || "",
        usage: parseInt(contract.calculator.values.energyUsage || ""),
      },
      ...reasonForContract,
      ...billingAddressData,
      ...promoCode,
    };
    const fetchData = async () => {
      try {
        setContract({ isPending: true, data: null, error: null });
        dispatch(setIsPreloader(true));
        const promise = createContract(contractData);
        const data = await promise;
        localStorage.setItem("firstName", contractData.firstName);
        setContract({ isPending: false, data: data, error: null });
        dispatch(setIsPreloader(false));
        navigate("/check-email");
      } catch (error) {
        setContract({ isPending: false, data: null, error });
        dispatch(setIsPreloader(false));
      }
    };
    if (isFormSubmitted) {
      fetchData();
    }
  }, [isFormSubmitted]);
  return contractResponse;
};
