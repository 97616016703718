import React, {
  ReactElement,
  SyntheticEvent,
  useState,
  useEffect,
} from "react";
import { useForm } from "react-hook-form";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";

import { Paper } from "../../Paper";
import { TextField } from "../Form";
import { FormRow } from "../CalculatorForm/styled";
import {
  ContractSteps,
  ContractForms,
  RelocationFormValues,
} from "../../../types";
import { AgreementInformation, AgreementInformationContainer } from "./styled";
import { validateMeterNumber } from "../validation";
import { useFormState } from "../hooks/useFormState";
import {
  datePlusYear,
  dateMinusDays,
  getDate,
  getInitialDate,
} from "./formattedDates";
import { DatePicker } from "../PersonalInformationForm/styled";
import { DatePickerPopover } from "../styled";
import { useTrackStepEventByName } from "../hooks/useTrackStepEventByName";

const useStyles = makeStyles({
  popoverPropsStyle: DatePickerPopover,
});

interface RelocationFormProps {
  isFormSubmitted: boolean;
  isFormEdited: boolean;
  initValues: RelocationFormValues;
}

export const RelocationForm = ({
  initValues,
  isFormSubmitted,
  isFormEdited,
}: RelocationFormProps): ReactElement => {
  const {
    register,
    formState,
    errors,
    getValues,
    setValue,
    setError,
    clearError,
    triggerValidation,
  } = useForm<RelocationFormValues>({
    mode: "onChange",
    defaultValues: initValues,
  });

  useTrackStepEventByName(ContractSteps.relocation);

  const classes = useStyles();
  const [selectedDesiredDate, handleDesiredChange] = useState(
    getInitialDate(initValues.relocationDate)
  );
  const [firstSupplier, setFirstSupplier] = useState(initValues.firstSupplier);
  const [checkedPerformRegistration, setCheckedPerformRegistration] = useState<
    boolean
  >(initValues.performRegistration);
  const { isValid } = formState;
  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    register({ name: "firstSupplier", type: "custom" });
    register(
      { name: "performRegistration", type: "custom" },
      { required: true }
    );
    register({ name: "relocationDate", type: "custom" }, { required: true });
    triggerValidation(["relocationDate"]);
  }, []);
  useFormState(
    isValid,
    isSubmitted,
    isFormEdited,
    getValues,
    ContractForms.relocation,
    "policies"
  );
  useEffect(() => {
    setIsSubmitted(isFormSubmitted);
  }, [isFormSubmitted]);

  const changeFirstSupplier = (event: SyntheticEvent<EventTarget>) => {
    const { checked } = event.target as HTMLInputElement;
    setFirstSupplier(checked);
    setValue("firstSupplier", checked);
  };
  const changePerformRegistration = (event: SyntheticEvent<EventTarget>) => {
    const { checked } = event.target as HTMLInputElement;
    setCheckedPerformRegistration(checked);
    setValue("performRegistration", checked);
    triggerValidation("performRegistration");
  };

  return (
    <Paper>
      <form>
        <FormRow>
          <AgreementInformationContainer>
            <Checkbox
              id="firstSupplier"
              name="firstSupplier"
              checked={firstSupplier}
              onChange={changeFirstSupplier}
              inputProps={{ "aria-label": "primary checkbox" }}
              style={{
                color: "#4646eb",
                padding: 0,
              }}
            />
            <AgreementInformation>
              Erster Lieferant in einem neuen Gebäude: Ich ziehe in ein neues
              Gebäude oder eine sanierte Wohnung. Im neuen Gebäude oder bei der
              Sanierung wurde ein neuer Stromzähler installiert.
            </AgreementInformation>
          </AgreementInformationContainer>
        </FormRow>
        <FormRow>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
            <DatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="Wähle dein Wunschdatum aus"
              format="dd.MM.yyyy"
              placeholder="Pflichtfeld"
              value={selectedDesiredDate}
              InputAdornmentProps={{
                position: "end",
              }}
              PopoverProps={{ className: `${classes.popoverPropsStyle}` }}
              onError={(error) => {
                error && setError("relocationDate", error.toString());
              }}
              onChange={(val) => {
                let date = null;
                try {
                  date = val ? getDate(new Date(val)) : null;
                } catch (err) {
                  date = null;
                }
                clearError("relocationDate");
                val && setValue("relocationDate", date);
                val && handleDesiredChange(val);
                triggerValidation(["relocationDate"]);
              }}
              invalidDateMessage="Bitte gib ein gültiges Datum ein"
              minDate={dateMinusDays}
              maxDate={datePlusYear}
              maxDateMessage="Bitte gib ein gültiges Datum ein"
              minDateMessage="Bitte gib ein gültiges Datum ein"
            />
          </MuiPickersUtilsProvider>
        </FormRow>
        <FormRow>
          <TextField
            id="meteringNumber"
            name="meteringNumber"
            type="text"
            label="Zählernummer"
            placeholder="Pflichtfeld"
            error={Boolean(errors.meteringNumber?.message)}
            helperText={errors.meteringNumber?.message}
            inputProps={{ maxLength: 18 }}
            inputRef={register({ validate: validateMeterNumber })}
          />
        </FormRow>
        <FormRow>
          <AgreementInformationContainer>
            <Checkbox
              id="performRegistration"
              name="performRegistration"
              checked={checkedPerformRegistration}
              onChange={changePerformRegistration}
              inputProps={{ "aria-label": "primary checkbox" }}
              style={{
                color: "#4646eb",
                padding: 0,
              }}
            />
            <AgreementInformation>
              Hiermit bevollmächtige ich die homee GmbH, eine Anmeldung beim
              örtlichen Netzbetreiber durchzuführen und meinen bestehenden
              Vertrag mit meinem derzeitigen Lieferanten für den angegebenen
              Lieferpunkt zu kündigen.
            </AgreementInformation>
          </AgreementInformationContainer>
        </FormRow>
      </form>
    </Paper>
  );
};
