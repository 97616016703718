import React, { ReactElement } from "react";

import changeOfProvider from "../../../assets/change-of-provider.svg";
import relocation from "../../../assets/relocation.svg";

import { ButtonWithText } from "./index";
import { ReasonForContractWrapper } from "./styled";
import { useTrackStepEventByName } from "../hooks/useTrackStepEventByName";
import { ContractSteps } from "../../../types";

interface ReasonForContractProps {
  onClickChangeOfProvider: () => void;
  onClickRelocation: () => void;
}

const ReasonForContract = ({
  onClickChangeOfProvider,
  onClickRelocation,
}: ReasonForContractProps): ReactElement => {
  useTrackStepEventByName(ContractSteps.reasonForContract);

  return (
    <ReasonForContractWrapper>
      <ButtonWithText
        image={changeOfProvider}
        imageAlt="Anbieterwechsel"
        title="Anbieterwechsel"
        description="Du hast noch einen Vertrag bei einem anderen
        Stromanbieter und möchtest jetzt zu stromee wechseln."
        onClick={onClickChangeOfProvider}
      />
      <ButtonWithText
        image={relocation}
        imageAlt="Umzug"
        title="Umzug"
        description="Du ziehst um und hast noch keinen Stromanbieter für dein neues Zuhause.
        Oder es handelt sich um einen Erstbezug nach Neubau oder Sanierung."
        onClick={onClickRelocation}
      />
    </ReasonForContractWrapper>
  );
};

export default ReasonForContract;
