import { useEffect, useState } from "react";
import { getIban } from "../../../../services";
import { createRequestState } from "../../../../utils";
import { PersonalDataFormValues, Iban } from "../../../../types";

export const useIban = (
  iban: string,
  triggerValidation: (payload: Array<keyof PersonalDataFormValues>) => void
) => {
  const [ibanData, setIbanName] = useState(createRequestState<Iban>());
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIbanName({ isPending: true, data: null, error: null });
        const formatIban = iban.replace(/\s/g, "");
        const promise = getIban(formatIban);
        const data = await promise;
        setIbanName({ isPending: false, data, error: null });
        triggerValidation(["iban"]);
      } catch (error) {
        setIbanName({ isPending: false, data: null, error });
        triggerValidation(["iban"]);
      }
    };
    if (iban && iban.length) {
      fetchData();
    }
  }, [iban]);
  return ibanData;
};
