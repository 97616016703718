import React from "react";
import { formatDate } from "../utils";

import {
  PreviewTitle,
  PreviewRow,
  PreviewRowLeftSide,
  PreviewRowRightSide,
} from "./styled";

interface ReasonForContractPreviewProps {
  reason: string;
  desiredDate: string;
  meteringNumber?: string;
  previousSupplier?: string;
}

export const ReasonForContractPreview: React.FC<ReasonForContractPreviewProps> = ({
  reason,
  desiredDate,
  meteringNumber,
  previousSupplier,
}) => {
  return (
    <div>
      <PreviewTitle>Wechselinformation</PreviewTitle>
      <PreviewRow>
        <PreviewRowLeftSide>Grund des Wechsels</PreviewRowLeftSide>
        <PreviewRowRightSide>{reason}</PreviewRowRightSide>
      </PreviewRow>
      <PreviewRow>
        <PreviewRowLeftSide>Gewünschter Lieferstart</PreviewRowLeftSide>
        <PreviewRowRightSide>{formatDate(desiredDate)}</PreviewRowRightSide>
      </PreviewRow>
      {meteringNumber && (
        <PreviewRow>
          <PreviewRowLeftSide>Zählernummer</PreviewRowLeftSide>
          <PreviewRowRightSide>{meteringNumber}</PreviewRowRightSide>
        </PreviewRow>
      )}
      {previousSupplier && (
        <PreviewRow>
          <PreviewRowLeftSide>Bisheriger Lieferant</PreviewRowLeftSide>
          <PreviewRowRightSide>{previousSupplier}</PreviewRowRightSide>
        </PreviewRow>
      )}
    </div>
  );
};
