import styled from "styled-components";

export const ButtonWrapper = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: 555px;
  min-height: 245px;
  padding: 40px 98px 30px 30px;
  text-align: left;
  box-shadow: 0 0 26px 0 rgba(10, 31, 68, 0.12);
  background-color: #ffffff;
  border-radius: 16px;
  outline: none;
  border: none;
  transition: all 0.5s ease-in-out;

  &:hover {
    box-shadow: 0 18px 18px 0 rgba(10, 31, 68, 0.12),
      0 0 1px 0 rgba(10, 31, 68, 0.1);
  }
  @media (max-width: 767px) {
    padding: 32px 16px;
  }
`;

export const Image = styled.img`
  margin-bottom: 30px;
`;

export const Title = styled.span`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.32;
  color: #4646eb;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.31;
  color: #263242;
`;

export const ReasonForContractWrapper = styled.div`
  ${ButtonWrapper} {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 992px) {
    margin-bottom: 99px;
  }
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`;
