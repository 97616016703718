import React from "react";
import {
  PreviewTitle,
  PreviewRow,
  PreviewRowLeftSide,
  PreviewRowRightSide,
} from "./styled";

export const BillingAlternativeAddress = (data: any) => {
  return (
    <div>
      <PreviewTitle>Rechnungsadresse</PreviewTitle>
      {!data.isBillingAlternativeData && (
        <React.Fragment>
          <PreviewRow>
            <PreviewRowLeftSide>Postleitzahl</PreviewRowLeftSide>
            <PreviewRowRightSide>{data.postalCode}</PreviewRowRightSide>
          </PreviewRow>
          <PreviewRow>
            <PreviewRowLeftSide>Adresse</PreviewRowLeftSide>
            <PreviewRowRightSide>
              {data.cityName}, {data.street}, {data.houseNumber}
            </PreviewRowRightSide>
          </PreviewRow>
        </React.Fragment>
      )}
      {data.isBillingAlternativeData && (
        <React.Fragment>
          <PreviewRow>
            <PreviewRowLeftSide>Vorname</PreviewRowLeftSide>
            <PreviewRowRightSide>{data.billingFirstName}</PreviewRowRightSide>
          </PreviewRow>
          <PreviewRow>
            <PreviewRowLeftSide>Nachname</PreviewRowLeftSide>
            <PreviewRowRightSide>{data.billingSurname}</PreviewRowRightSide>
          </PreviewRow>
          <PreviewRow>
            <PreviewRowLeftSide>Postleitzahl</PreviewRowLeftSide>
            <PreviewRowRightSide>{data.billingZip}</PreviewRowRightSide>
          </PreviewRow>
          <PreviewRow>
            <PreviewRowLeftSide>Adresse</PreviewRowLeftSide>
            <PreviewRowRightSide>
              {data.billingCityName}, {data.billingStreet},{" "}
              {data.billingHouseNumber}
            </PreviewRowRightSide>
          </PreviewRow>
          <PreviewRow>
            <PreviewRowLeftSide>E-Mail</PreviewRowLeftSide>
            <PreviewRowRightSide>{data.billingEmail}</PreviewRowRightSide>
          </PreviewRow>
          <PreviewRow>
            <PreviewRowLeftSide>Telefonnummer</PreviewRowLeftSide>
            <PreviewRowRightSide>
              {data.billingPhoneAreaCode}
              {data.billingPhone}
            </PreviewRowRightSide>
          </PreviewRow>
        </React.Fragment>
      )}
    </div>
  );
};
