import React, { ReactElement } from "react";

import { StepsStaticData } from "../ContractStaticData";

import { Title, Description } from "./styled";
import { ContractStep } from "../../../types";

interface StepProps {
  currentStep: ContractStep;
}

const DescriptionBlock = (props: StepProps): ReactElement => {
  const { title, description } = StepsStaticData[props.currentStep];
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </React.Fragment>
  );
};

export default DescriptionBlock;
