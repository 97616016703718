import { makeStyles } from "@material-ui/core/styles";
import { DatePickerPopover } from "../../styled";

export const labelStyle = {
  border: "1px solid #ecf0f1",
  borderRadius: "16px",
  padding: "16px 16px",
  width: "100%",
  margin: "16px 0 0 0",
  "& .MuiTypography-root": {
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontSize: "14px",
    lineHeight: "1.36",
    color: "#263242",
  },
  "& .MuiButtonBase-root": {
    marginRight: "9px",
    padding: 0,
  },
};

export const useStyles = makeStyles({
  formControlStyles: {
    width: "100%",
  },
  radioButtonStyles: {
    color: "#526df2!important",
  },
  radioButtonContainerStyle: {
    ...labelStyle,
  },
  checkedRadio: {
    ...labelStyle,
    backgroundColor: "#f6f9fa",
    border: "none",
  },
  radioButtonsContainerStyle: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    marginTop: "16px",
    display: "flex",
    flexDirection: "column",
    marginRight: "14px",
    marginLeft: "14px",
  },
  popoverPropsStyle: DatePickerPopover,
});
