import React from "react";

import { useBillingAddressData } from "./hooks/useBillingAddressData";
import { BillingAddressTitle, BillingAddressValue } from "./styled";

export const BillingAddressBlock = () => {
  const {
    isBillingAlternativeAddress,
    billingFirstName,
    billingSurname,
    billingZip,
    billingCityName,
    billingStreet,
    billingHouseNumber,
    billingEmail,
    billingPhoneAreaCode,
    billingPhone,
  } = useBillingAddressData();
  return (
    <React.Fragment>
      {isBillingAlternativeAddress && (
        <React.Fragment>
          <div>
            <BillingAddressTitle>
              Vorname - Rechnungsadresse
            </BillingAddressTitle>
            <BillingAddressValue>{billingFirstName}</BillingAddressValue>
          </div>
          <div>
            <BillingAddressTitle>
              Nachname - Rechnungsadresse
            </BillingAddressTitle>
            <BillingAddressValue>{billingSurname}</BillingAddressValue>
          </div>
        </React.Fragment>
      )}
      <div>
        <BillingAddressTitle>Postleitzahl</BillingAddressTitle>
        <BillingAddressValue>{billingZip}</BillingAddressValue>
      </div>
      <div>
        <BillingAddressTitle>Adresse</BillingAddressTitle>
        <BillingAddressValue>
          {billingCityName}, {billingStreet}, {billingHouseNumber}
        </BillingAddressValue>
      </div>
      {isBillingAlternativeAddress && (
        <React.Fragment>
          <div>
            <BillingAddressTitle>E-Mail</BillingAddressTitle>
            <BillingAddressValue>{billingEmail}</BillingAddressValue>
          </div>
          <div>
            <BillingAddressTitle>Telefonnummer</BillingAddressTitle>
            <BillingAddressValue>
              {billingPhoneAreaCode}
              {billingPhone}
            </BillingAddressValue>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
