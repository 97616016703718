import React, { ReactElement } from "react";
import {
  CopyBlock,
  PaymentInfo,
  PaymentInfoBold,
  PaymentInfoFirstBlock,
  CopyIconWrapper,
} from "./styled";
import { withCopyTooltip } from "../../../Tooltip";
import { ReactComponent as CopyIcon } from "../../../../assets/copy-icon.svg";

const CopyIconButton = () => {
  return (
    <CopyIconWrapper>
      <CopyIcon
        style={{ width: "100%", height: "100%" }}
        preserveAspectRatio="xMinYMin meet"
        viewBox="0 0 16 16"
      />
    </CopyIconWrapper>
  );
};

export const PaymentDetails = (): ReactElement => {
  const iban = "DE29 1207 0024 0823 5095 02";
  const IconWithCopyTooltip = withCopyTooltip(CopyIconButton, iban);
  return (
    <div>
      <PaymentInfoFirstBlock>
        <PaymentInfo>
          Bitte überweise deine Abschlagszahlungen immer zum{" "}
          <PaymentInfoBold>5. Kalendertag</PaymentInfoBold> des Monats und{" "}
          <PaymentInfoBold>unter Angabe deiner Kundennummer</PaymentInfoBold> an
          folgende Bankverbindung:
        </PaymentInfo>
      </PaymentInfoFirstBlock>
      <div>
        <PaymentInfo>homee GmbH </PaymentInfo>
        <br />
        <CopyBlock>
          <PaymentInfo>IBAN: {iban} </PaymentInfo>
          <IconWithCopyTooltip />
        </CopyBlock>
        <PaymentInfo>BIC: DEUTDEDB160</PaymentInfo>
      </div>
    </div>
  );
};
