import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Provider, RequestState } from "../../../../types";
import { createRequestState } from "../../../../utils";
import { getProviders } from "../../../../services";

export const useProviders = (): RequestState<Provider[]> => {
  const state = useSelector((state: any) => state);
  const { cityId, energyUsage } = state.contract["calculator"].values;
  const [providers, setProviders] = useState(createRequestState<Provider[]>());
  useEffect(() => {
    const fetchData = async () => {
      try {
        setProviders({ isPending: true, data: null, error: null });
        const promise = getProviders({
          cityId: parseInt(cityId),
          usage: parseInt(energyUsage),
        });
        const data = await promise;
        setProviders({ isPending: false, data, error: null });
      } catch (error) {
        setProviders({ isPending: false, data: null, error });
      }
    };
    fetchData();
  }, []);
  return providers;
};
