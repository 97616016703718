import { useEffect } from "react";

import { StepsAnalyticsEventData } from "../ContractStaticData";
import { ContractStep } from "../../../types";

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const useTrackStepEventByName = (stepName: ContractStep): void => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(StepsAnalyticsEventData[stepName].view);
    return () => window.dataLayer.push(StepsAnalyticsEventData[stepName].close);
  }, [stepName]);
};
