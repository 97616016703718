import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  goNextStep,
  setFormState,
  setFormValues,
} from "../../../store/actions";
import {
  ContractForms,
  ContractFormValues,
  ContractStep,
} from "../../../types";

export const useFormState = (
  isValid: boolean,
  isFormSubmitted: boolean,
  isFormEdited: boolean,
  getValues: () => ContractFormValues,
  contractStep: ContractForms,
  nextStep: ContractStep
) => {
  const dispatch = useDispatch();
  const updateForm = () => {
    const values = getValues();
    dispatch(
      setFormState(contractStep, {
        isSubmitting: true,
        isValid: true,
      })
    );
    dispatch(setFormValues(contractStep, values));
  };
  useEffect(() => {
    dispatch(
      setFormState(contractStep, {
        isSubmitting: false,
        isValid: isValid,
      })
    );
  }, [isValid]);
  useEffect(() => {
    if (isFormSubmitted && isValid) {
      dispatch(goNextStep(nextStep));
      updateForm();
    }
    if (isFormEdited && isValid) {
      dispatch(goNextStep("policies"));
      updateForm();
    }
  }, [isFormSubmitted, isValid, isFormEdited]);
};
