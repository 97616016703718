import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import {
  ContractForms,
  Provider,
  SwitchOfSupplierFormValues,
} from "../../../../types";
import { useFormState } from "../../hooks/useFormState";
import { useProviders } from "../hooks/useProviders";
import { getDate, getInitialDate } from "../formattedDates";
import { SwitchOfSupplierFormProps } from "./SwitchOfSupplierForm";
import { useStyles } from "./styles";
import { FormContextValues } from "react-hook-form/dist/contextTypes";
import { useForm } from "react-hook-form";

interface SwitchOfSupplierFormApi {
  register: FormContextValues["register"];
  errors: FormContextValues["errors"];
  setValue: FormContextValues["setValue"];
  watch: FormContextValues["watch"];
  triggerValidation: FormContextValues["triggerValidation"];
  setError: (name: "desiredDate" | "terminationDate", type: string) => void;
  clearError: FormContextValues["clearError"];
  providers: Provider[] | null;
  changeContractCancel: (event: SyntheticEvent<EventTarget>) => void;
  getCheckedRadioStyles: (isChecked: boolean) => string;
  classes: Record<
    | "formControlStyles"
    | "radioButtonStyles"
    | "radioButtonContainerStyle"
    | "checkedRadio"
    | "radioButtonsContainerStyle"
    | "popoverPropsStyle",
    string
  >;
  isCancellationDate: boolean;
  selectedTerminatedDate: Date | null;
  handleTerminatedDate: Dispatch<SetStateAction<Date | null>>;
  changeCustomerSpecification: (event: SyntheticEvent<EventTarget>) => void;
  customerSpecification: string;
  selectedDesiredDate: Date | null;
  handleDesiredChange: Dispatch<SetStateAction<Date | null>>;
  performRegistration: boolean;
  changePerformRegistration: (event: SyntheticEvent<EventTarget>) => void;
}

export const useSwitchOfSupplierForm = ({
  initValues,
  isFormSubmitted,
  isFormEdited,
}: SwitchOfSupplierFormProps): SwitchOfSupplierFormApi => {
  const {
    register,
    unregister,
    formState,
    errors,
    getValues,
    setValue,
    watch,
    triggerValidation,
    setError,
    clearError,
  } = useForm<SwitchOfSupplierFormValues>({
    mode: "onChange",
    defaultValues: initValues,
  });

  const classes = useStyles();

  const [selectedDesiredDate, handleDesiredChange] = useState(
    getInitialDate(initValues.desiredDate)
  );
  const [selectedTerminatedDate, handleTerminatedDate] = useState(
    getInitialDate(initValues.terminationDate || null)
  );
  const [isCancellationDate, setIsCancellationDate] = useState(
    initValues.contractCancel === "alreadyCanceled"
  );
  const [customerSpecification, setCustomerSpecification] = useState(
    initValues.customerSpecification || "earliest_possible_date"
  );
  const [performRegistration, setPerformRegistration] = useState(
    initValues.performRegistration
  );
  const { isValid } = formState;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const providers = useProviders().data;

  useFormState(
    isValid,
    isSubmitted,
    isFormEdited,
    getValues,
    ContractForms.switchOfSupplier,
    "policies"
  );

  const getFormattedDate = (date: Date | null) => {
    if (date === null) {
      return null;
    }
    return getDate(new Date(date));
  };

  useEffect(() => {
    register({ name: "contractCancel", type: "custom" });
    register({ name: "customerSpecification", type: "custom" });
    register(
      { name: "performRegistration", type: "custom" },
      { required: true }
    );
  }, []);

  useEffect(() => {
    if (isCancellationDate) {
      register({ name: "terminationDate", type: "custom" }, { required: true });
      setValue("terminationDate", getFormattedDate(selectedTerminatedDate));
      triggerValidation(["terminationDate"]);
    } else {
      unregister("terminationDate");
      setValue("terminationDate", initValues.terminationDate);
      handleTerminatedDate(getInitialDate(initValues.terminationDate));
      triggerValidation(["terminationDate"]);
    }
  }, [isCancellationDate]);

  useEffect(() => {
    if (customerSpecification === "desired_date") {
      register(
        { name: "desiredDate", type: "custom" },
        { required: customerSpecification === "desired_date" }
      );
      setValue("desiredDate", getFormattedDate(selectedDesiredDate));
    } else {
      unregister("desiredDate");
      setValue("desiredDate", initValues.desiredDate);
      handleDesiredChange(getInitialDate(initValues.desiredDate));
      triggerValidation(["desiredDate"]);
    }
    triggerValidation(["desiredDate"]);
  }, [customerSpecification]);

  useEffect(() => {
    if (initValues.previousSupplier?.length && providers) {
      triggerValidation(["previousSupplier"]);
    }
  }, [initValues.previousSupplier, providers]);

  useEffect(() => {
    setIsSubmitted(isFormSubmitted);
  }, [isFormSubmitted]);

  const changeContractCancel = (event: SyntheticEvent<EventTarget>) => {
    const { value } = event.target as HTMLInputElement;
    setValue("contractCancel", value);
    if (value === "stromeeCancel") {
      setIsCancellationDate(false);
    } else {
      setIsCancellationDate(true);
    }
  };
  const changeCustomerSpecification = (event: SyntheticEvent<EventTarget>) => {
    const { value } = event.target as HTMLInputElement;
    setValue("customerSpecification", value);
    setCustomerSpecification(value);
  };
  const changePerformRegistration = (event: SyntheticEvent<EventTarget>) => {
    const { checked } = event.target as HTMLInputElement;
    setPerformRegistration(checked);
    setValue("performRegistration", checked);
    triggerValidation("performRegistration");
  };

  const getCheckedRadioStyles = (isChecked: boolean) => {
    if (!isChecked) {
      return classes.radioButtonContainerStyle;
    }
    return classes.checkedRadio;
  };

  return {
    register,
    errors,
    setValue,
    watch,
    triggerValidation,
    setError,
    clearError,
    providers,
    changeContractCancel,
    getCheckedRadioStyles,
    classes,
    isCancellationDate,
    selectedTerminatedDate,
    handleTerminatedDate,
    changeCustomerSpecification,
    customerSpecification,
    selectedDesiredDate,
    handleDesiredChange,
    performRegistration,
    changePerformRegistration,
  };
};
